export const TOKEN = 'token';
export const LOGIN_STORAGE_VAR = '_logged_user_details';
export const EDIT_ID = '_edit_id';

export const AUTH_TOKEN = '_authToken';
export const USER_ID = 'logged-in-user-id';
export const USER_EMAIL = 'logged-in-user-email';

export const GOOGLE_SIGNUP = 'Continue with Google';
export const GOOGLE_LOGIN = 'Continue with Google';

export const JWT_SECRET = 'seo.io';

export const OAUTH_CLIENT_ID =
  '399455137742-kpj1o5efpgf91iqvmk71qbssrichp1vh.apps.googleusercontent.com';
export const LIVE_MODE_URL = '/dev';
// export const API_BASEURL = 'https://joy-seo-backend.azurewebsites.net/api/v1';
// export const API_BASEURL = 'http://localhost:8000/api/v1';
export const API_BASEURL =
  process.env.REACT_APP_API_URL || `http://localhost:8000/api/v1`;

export const API_STATUS = {
  initial: 'INITIAL',
  progress: 'PROGRESS',
  failed: 'FAILED',
  success: 'SUCCESS', // hello
};

export const test = 'test';
