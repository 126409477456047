import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from "@chakra-ui/modal"
import { BaseApi } from "common/api/common/base-api"

const DeleteScheduleDrawer = ({ deleteState, setDeleteState, setScheduleList }: any) => {
    console.log(deleteState)
    const deleteSchedule = async ()=>{
        const response = await BaseApi.delete(`/time/schedule/${deleteState.schedule_id}`)
        if (response?.error) {
            console.log(response?.error)
        }
        else {
            console.log(deleteState.schedule_id, 'deleted')
            setDeleteState({ clicked: false, title: ''})
            try {
                const response = await BaseApi.get('/time/schedule');
                if (response?.data && response?.data?.length) {
                    setScheduleList(response?.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <Drawer placement="right" onClose={() => { setDeleteState({ clicked: false, title: '' }) }} isOpen={deleteState.clicked} size={'md'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader className='flex gap-3 items-center border-b-2 mx-3'>
                    Confirm Deletion
                </DrawerHeader>
                <DrawerCloseButton className="text-brand-500" />

                <DrawerBody className='flex items-center justify-center'>
                    <h4 className='text-center w-full text-4xl text-brand-900'>Are you sure want to delete {deleteState.title}?</h4>
                </DrawerBody>
                <DrawerFooter className='flex !justify-center gap-3 mb-8'>
                    <button className="px-24 text-sm rounded-lg font-bold duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600" onClick={() => { setDeleteState({ clicked: false, title: '' }) }}>No</button>
                    <button className="px-24 text-sm rounded-lg font-bold duration-300 py-2 border text-white border-brand-500 bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600" onClick = {deleteSchedule}>Yes</button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default DeleteScheduleDrawer;