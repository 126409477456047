import { useAppDispatch } from 'store'
import CampaignSetUpDiagram from '../campaign-setup/flow-diagram'
import { setCurrentHeaderSteps } from 'store/campaign'

const CampaignReview = () => {
  const dispatch = useAppDispatch()
  return (
    
    <div className='w-1/3 bg-indigo-50 rounded-2xl drop-shadow-xl shadow-gray-500/50 grow border'>
      <div className='p-5 flex justify-between bg-white rounded-t-2xl border-b'>
    <div className='ml-3'>
    <p className="text-lg font-extrabold text-navy-700 dark:text-white">
    Sequence Overview
    </p>
    {/* <p className="text-sm text-gray-600 mt-1">
    Vivamus rhoncus neque ut amet non dolor quis viverra. Eget sed ac eu semper egestas ut.</p> */}
    {/* <CampaignSequence /> */}
    </div>
    <button onClick={()=> dispatch(setCurrentHeaderSteps(2))}
     className='bg-brand-500 text-white text-sm py-2.5 2xl:px-10 sm:px-2 2xl:my-1 sm:my-3 rounded-xl shadow-xl shadow-brand-100'>
      Edit Sequence</button>
      </div>
    <div className='flex justify-center'>
<CampaignSetUpDiagram width='full' preview = {true}/>
    </div>
        </div>
    
  )
}

export default CampaignReview