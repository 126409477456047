import { MdAdd, MdRemove } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store'
import { setCampaignScale } from 'store/campaign'

const Scaler = () => {
  const dispatch = useDispatch()
  const scale = useAppSelector((state) => state?.campaign?.campaignScale)
  return (
    <div className='w-1/12 flex justify-around items-center absolute top-10 left-[10%] gap-2 z-10'>
      <button className="linear shadow-lg shadow-blue-700/50 flex items-center justify-center rounded-lg bg-brand-500 p-1 text-xl text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        <MdRemove onClick={() => dispatch(setCampaignScale("Decrease"))} />
      </button>
      <h5 className={`text-sm font-bold text-navy-700 dark:text-white select-none`}> {scale + 30}%</h5>
      <button className={`linear flex items-center justify-center rounded-lg
      shadow-lg shadow-blue-700/50 bg-brand-500 p-1 text-xl text-white transition duration-200
       hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white 
       dark:hover:bg-brand-300 dark:active:bg-brand-200`}>
        <MdAdd onClick={() => dispatch(setCampaignScale("Increase"))} />
      </button>
    </div>
  )
}

export default Scaler