import { useEffect, useState } from "react"
import { IoMdAdd } from "react-icons/io"
import { RiSubtractFill } from "react-icons/ri"
import { useAppDispatch } from "store";
import { setCampaignScheduleAndTrackingData } from "store/campaign";

const TrackingComponent = () => {
    const dispatch = useAppDispatch();
    const [trackingData, setTrackingData] = useState<any>({})
    const [gapBetweenEmail, setGapBetweenEmail] = useState({ value: 0, type: "minutes" })
    const trackingOptionData = [
        { name: "Track Opens", id: "track_opens" },
        { name: "Track link clicks", id: "track_link_clicks" },
    ]
    // Stop Reply Data-
    const stopReplyData = [
        {
            name: "Reply for an email",
            id: 'reply_an_email'
        },
        {
            name: "Reply for Linkedin message",
            id: 'reply_linkedin_message'
        },
        {
            name: "Call happened",
            id: 'call_happened'
        },
        {
            name: "Clicks on a link",
            id: 'click_on_link'
        },
    ]
    const handleDelayChange = (type: string) => {
        type === "increase" ? setGapBetweenEmail((prev) => ({ ...prev, value: prev.value + 1 })) : setGapBetweenEmail((prev) => ({ ...prev, value: prev.value - 1 }))
    }
    const handleTrackingChange = (e: any) => {
        setTrackingData((prev: any) => ({ ...prev, [e.target.id]: e.target.checked }))
    }
    useEffect(() => {
        dispatch(setCampaignScheduleAndTrackingData({ tracking_options: trackingData, gap_between_email: gapBetweenEmail }))
    }, [dispatch, gapBetweenEmail, trackingData])

    // Check box element
    const CheckBoxComponent = ({ name, id, handleTrackingChange }: { name: string, id: string, handleTrackingChange: any }) => {
        return (
            <div className="flex items-center mb-2">
                <input checked={trackingData[id]} onChange={(e) => handleTrackingChange(e)} id={id} type="checkbox" className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                <label htmlFor={id} className="ml-3 text-[13px] font-semibold cursor-pointer text-gray-800 dark:text-gray-300">{name}</label>
            </div>
        )
    }
    return (
        <div className="w-full px-12 my-4">
            {/* Tracking option */}
            <div className='my-8'>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                    Tracking Options
                </h4>
                <div className='my-3'>
                    {
                        trackingOptionData.map((element, index) => {
                            return (
                                <CheckBoxComponent handleTrackingChange={handleTrackingChange} name={element.name} key={index} id={element?.id} />
                            )
                        })
                    }
                </div>
            </div>

            {/* Tracking option */}
            <div className='my-8'>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                    Stop Reply To
                </h4>
                <div className='my-3'>
                    {
                        stopReplyData.map((element, index) => {
                            return (
                                <CheckBoxComponent handleTrackingChange={handleTrackingChange} name={element.name} key={index} id={element?.id} />
                            )
                        })
                    }
                </div>
            </div>

            {/* Advanced Options */}
            <div className='my-8'>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                    Advanced Options
                </h4>
                <div className='my-3'>
                    <CheckBoxComponent handleTrackingChange={handleTrackingChange} name='Text Only (disable open rate)' id="text_only_disable_open_rate" />
                    <div className='my-3 flex gap-2 items-center'>
                        <button disabled={gapBetweenEmail?.value <= 0} className={`p-1 rounded-md bg-blue-50 text-blue-600 active:bg-blue-100 duration-300 ${gapBetweenEmail?.value <= 2 && 'text-gray-600 bg-gray-50'}`} onClick={() => handleDelayChange('decrease')}><RiSubtractFill /></button>
                        <span className='border border-gray-100 w-10 text-center px-2 py-0.5 rounded-md text-base text-gray-600'>{gapBetweenEmail?.value}</span>
                        <button className='p-1 rounded-md bg-blue-50 text-blue-600 active:bg-blue-100 duration-300' onClick={() => handleDelayChange('increase')}><IoMdAdd /></button>
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-300">Time gap between email</span>
                    </div>
                    <CheckBoxComponent handleTrackingChange={handleTrackingChange} name='Prioritize new leads' id="prioritize_new_leads" />
                </div>
            </div>
        </div>
    )
}


export default TrackingComponent;