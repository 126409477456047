import { MdOutlineCloudUpload } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";
import { toastState } from "common/utils/utility";

interface propType {
    onChange:(value:any)=>void;
}
// First Component
const UploadCSV:React.FC<propType> = ({onChange}) => {
    
    // const [drawerToggle, setDrawerToggle] = useState(false);

    // const dispatch = useAppDispatch();
    // const campaignStateData = useAppSelector((state) => state?.campaign)
    const fileMapUpload = new Map();

    function isValidUrl(value:any) {
        const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        return urlPattern.test(value);
    }
    // Droppable file or upload file
    const DropZonefile = (props: { content: JSX.Element }) => {
        const onDrop = useCallback((acceptedFiles: any[]) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                fileMapUpload.set(reader, file);
                reader.onload = async (e: any) => {
                    const fileAttr = fileMapUpload.get(e.target);
                    if (!['csv', 'xlsx', 'xls'].includes(fileAttr.name.split('.').pop())) {
                        toast.error("This file format is not accepted", toastState.error);
                        return;
                    }
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const filteredData = jsonData.flat();  
                    // console.log('filteredData',filteredData)

                    const finalData = filteredData.filter((value)=>isValidUrl(value)).map((val,index)=>{
                        return {id:index+1,name:val}
                    })
                    // console.log(finalData);
                    toast.success("File Uploaded Succesfully",toastState.success)
                    onChange(finalData?.slice(0,500))




                    
                    // dispatch(setCampaignProspectsEmailListUploadCSVNameAndCount({ file_name: fileAttr.name, rows_count: filteredData.length -1 }))
                    // dispatch(setCampaignProspectsEmailListUploadCSVData(filteredData))
                    // setDrawerToggle(true)
                };
                reader.readAsArrayBuffer(file)
            })
        }, [])
        const { content } = props;
        const { getRootProps, getInputProps } = useDropzone({ onDrop });
        return (
            <div
                className="flex h-full !w-[700px] cursor-pointer items-center justify-center rounded-xl border-dashed border-navy-700"
                {...getRootProps({ className: "dropzone" })}
            >
                <input {...getInputProps()} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                <button className="h-full !w-full"> {content} </button>
            </div>
        );
    };
    return (
        <div>
            <div className="h-full w-full px-2 pt-4">
                {/* content */}
                <div>
                    <DropZonefile
                        content={
                            <div id='dropzone-file-uploader' className="flex h-[225px] w-full p-3 flex-col items-center justify-center rounded-md border-[1px] border-dashed border-gray-200 
                             bg-[#FAFCFE] dark:!border-none dark:!bg-navy-700 gap-1">
                                <p className="text-[65px] text-navy-700 ml-2 pb-3">
                                    <MdOutlineCloudUpload className="text-brand-500 dark:text-white" />
                                </p>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    Drop your files here, or{" "}
                                    <span className="font-bold text-brand-500 dark:text-brand-400">
                                        browse
                                    </span>
                                </p>
                                <p className="pt-2 text-[10px] text-gray-700">
                                  CSV files are allowed
                                </p>
                            </div>
                        }
                    />
                </div>
            </div>
            {/* {campaignStateData?.campaignProspectsEmailListUploadCSVNameAndCount?.file_name &&
                      <ProspectsDrawer drawerToggle={drawerToggle} setDrawerToggle={setDrawerToggle} />
               
            } */}
        </div>
    )
}

export default UploadCSV