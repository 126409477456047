import React, { useState } from 'react';
import { IoMdMore } from 'react-icons/io';
import { MdOutlineCloudDownload } from 'react-icons/md';
import { saveAs } from 'file-saver';
// import { BaseApi } from 'common/api/common/base-api';
interface RowData {
  id?: string;
  uuid?: any;
  info?: any;
}
// interface csvTempData {
//     campaign_name: any,
//     cdate: any,
//     created_by: any,
//     campaign_status: any,
//     campaign_progress_step_percent: any,
//     campaign_delivery_message_data: any
// }

const RowSettingsOrganicKey = (props: RowData) => {
  const { info } = props;
  // const campaign = useAppSelector(state => state?.campaign)
  // const custom = useAppSelector(state => state?.custom)
  const [showOptions, setShowOptions] = useState(false);
  // const [data, setData] = useState([])

  const downloadCsv = (res: any) => {
    const csvContent = convertArrayOfObjectsToCSV(res);
    // console.log(csvContent);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  const convertArrayOfObjectsToCSV = (array: any): string => {
    if (array.length === 0) {
      return '';
    }
    const headers = Object.keys(array[0]).join(',');
    const rows = array.map((obj: any) => Object.values(obj).join(','));
    return [headers, ...rows].join('\n');
  };

  const handleOptions = (selectedOpt: string) => {
    if (selectedOpt === 'download') {
      // fetchData()
      const client_url_name = info?.original?.client_url_name;

      // let res = info?.original?.url_list?.flatMap((item: any) => {
      //     const prospect_url = item?.url;

      //     return item?.result?.map((resultItem: any) => ({
      //         client_url_name,
      //         prospect_url,
      //         country: resultItem.country,
      //         org_traffic: resultItem.org_traffic,
      //         org_keywords: resultItem.org_keywords,
      //         org_keywords_1_3: resultItem.org_keywords_1_3
      //     }));
      // }).filter(Boolean) || [];

      let res_data: any = [];
      for (let item of info?.original?.url_list) {
        const itemList = item?.result;
        for (let resultItemIndex in itemList) {
          res_data.push({
            client_url_name,
            prospect_url: item?.url,
            top_position: +resultItemIndex + 1,
            ...itemList[resultItemIndex],
          });
        }
      }


      // console.log(res, "RES CSV")
      downloadCsv(res_data);
      setShowOptions(false);
    }
  };

  return (
    <>
      <div
        className={`relative mr-3 w-min cursor-pointer rounded-md py-0.5 ${
          showOptions && 'bg-[#4318FF0A]'
        }`}
        tabIndex={0}
        id="seo-organic-keyword-list-download-btn"
        onBlur={() => setShowOptions(false)}
      >
        <IoMdMore
          className="z-0 text-lg text-gray-700"
          onClick={() => setShowOptions(true)}
        />
        {showOptions && (
          <div className="absolute -left-[75px] z-10 flex flex-col overflow-hidden rounded-md border bg-white">
            <div
              id="seo-ok-download-btn"
              className="campaign-download-status-btn group flex cursor-default gap-2 px-3 py-2 font-medium transition-all duration-150 hover:bg-brand-500 hover:text-white"
              onClick={() => handleOptions('download')}
            >
              <MdOutlineCloudDownload className="text-brand-500 group-hover:text-white" />
              <h5 className="text-xs">Download</h5>
            </div>
          </div>
        )}
      </div>
      {/* {custom?.globalOpt && <GlobalOpt handleOptions={handleOptions} />} */}
    </>
  );
};
export default RowSettingsOrganicKey;
