import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from 'common/dto/initial-value/store/custom';

const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    getErrorMessage: (state, action: PayloadAction<object>) => {
      state.errorMessage = action.payload;
    },
    setFlagStatus: (state, action: PayloadAction<boolean>) => {
      state.flagStatus = action.payload;
    }, 
  setGlobalOpt: (state, action)=>{
    state.globalOpt = action.payload
},
  }
});

export const { getErrorMessage, setFlagStatus, setGlobalOpt, } = customSlice.actions;
export default customSlice.reducer;
