import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/modal";
import CustomBadge from "components/badges/CustomBadge";
import { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Message from "./Message";
import PreviewAsideLeft from "./PreviewAsideLeft";

const PreviewEmail = () => {
  // Will move this data to redux in future
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [emailDetails, setEmailDetails] = useState({
    subject: '',
    message: '',
    to: {
      name: '',
      email: ''
    },
  })

  return (
    <>
      <button onClick={onOpen} id="preview-btn">
        <CustomBadge
          content={`Preview E-Mail`}
          color={''}
          bgColor={`indigo-50`}
          extra='w-full font-medium text-sm py-3 border'
        />
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
        <ModalOverlay />
        <ModalContent className="!mt-8 !mb-0">
          <div className="w-full h-full bg-white rounded-lg flex flex-start shadow-2xl overflow-hidden">
            <PreviewAsideLeft setEmailDetails={setEmailDetails} />
            <div className="w-full bg-indigo-50 px-10">
              <div className="flex justify-end items-center w-full text-brand-500 mt-7 mb-5">
                <button onClick={onClose} className="font-medium flex gap-2 items-center">
                  <IoCloseOutline className="text-3xl" />
                </button>
              </div>
              <Message emailDetails={emailDetails} />
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PreviewEmail