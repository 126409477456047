/* eslint-disable array-callback-return */
import React from 'react'
import AsideNav from './AsideNav'
import CampaignReview from './CampaignReview'
import { BaseApi } from 'common/api/common/base-api'
import { setAndGetQueryParams, jwtDecrypt } from 'common/utils/utility'
import { useAppSelector } from 'store'


const Review = () => {
  const campaign = useAppSelector((state) => state?.campaign);
  const [search, setSearch] = React.useState('');
  const [senderEmailsList, setSenderEmailsList] = React.useState([]);

  React.useEffect(() => {
    const fetchList = async () => {
        const query = setAndGetQueryParams([{ key: 'search', value: search }])
        try {
            const response = await BaseApi.get(`/email/settings?${query}`);
            const {data}:any = jwtDecrypt(response?.data)
            console.log('Sending accounts retrieved from Backend', data)
            if(data && data?.length){
                const res = data?.map((item:any) => {
                  if(campaign?.campaignSettingSenderEmailList.includes(item?.uuid) ){
                    return item?.email
                  }
                })
                console.log(res)
                setSenderEmailsList(res)
                return ""
            }
        } catch (error) {
            console.log(error)
            console.log(setSearch(''))
        }
    }
  fetchList();
}, [search, campaign?.campaignSettingSenderEmailList])
console.log('senderEmailsList', senderEmailsList)
  return (
    <div className='flex justify-between mt-[50px] gap-5'>
        <CampaignReview/>
        <AsideNav senderEmailsList={senderEmailsList} />
    </div>
  )
}

export default Review