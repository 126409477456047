import "./index.css";
import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "store";
import App from "./App";
// import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
        {/* <GoogleOAuthProvider clientId="678556597651-5v1hp8u2vlm82db3ggfl220n29r4c50h.apps.googleusercontent.com"> */}

      <App />
      {/* </GoogleOAuthProvider> */}
    </Provider>
  // </React.StrictMode>

);
