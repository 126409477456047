/* eslint-disable react/react-in-jsx-scope */
// import { AuthLogoWithNameImg } from "assets/img/auth"
import { BsFillLightningChargeFill } from "react-icons/bs";
import { MdCampaign, MdGrading, MdOutlineTrendingUp, MdRocketLaunch, MdTravelExplore, MdWebhook } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";

interface sideBarProps {
    showSideBar: boolean;
    setShowSideBar: (showSideBar: boolean) => void;
}

const AsideNav = (props: sideBarProps) => {
    const { showSideBar } = props
    // const [domainSubmenu, setDomainSubmenu] = useState(false)
    const navigate = useNavigate()
    const authLoggedUser = useAppSelector((state) => state?.auth?.loggedUser)
    // console.log(authLoggedUser)
    return (
        <div className={`h-screen bg-white dark:bg-navy-800 transition-all duration-300 rounded-md ${showSideBar ? `w-[24.4%] max-w-[210px] min-w-[150px]` : 'w-[5%]  min-w-[75px] max-w-[80px]'}`}>
            {/* <img src={AuthLogoWithNameImg} alt="" className="w-full h-24" /> */}
            <div className="w-full gap-2 px-5 mt-5 mb-7 flex items-center text-brand-500 dark:text-white text-2xl font-bold tracking-widest">
                <BsFillLightningChargeFill className={`${!showSideBar && 'size-8'}`} />
                <h5 className={`text-xl font-extrabold ${!showSideBar && 'hidden'}`}>SEO</h5>
            </div>
            <div className="mx-3 space-y-2.5 text-gray-800 dark:text-white font-medium cursor-default">

                <button id="site-validation-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/seo/list')}>
                    <MdCampaign className={` size-6 ${!showSideBar && 'size-8'}`} />
                    <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Site Validation</span>
                </button>

                <button id="traffic-country-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/seo/traffic/list')}>
                    <MdTravelExplore className={` size-6 ${!showSideBar && 'size-8'}`} />
                    <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Traffic Country</span>
                </button>

                <button id="traffic-trend-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/seo/traffictrend/list')}>
                    <MdOutlineTrendingUp className={` size-6 ${!showSideBar && 'size-8'}`} />
                    <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Traffic Trend</span>
                </button>

                <button id="organic-keyword-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/seo/organickeyword/list')}>
                    <MdGrading className={` size-6 ${!showSideBar && 'size-8'}`} />
                    <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Organic Keyword</span>
                </button>
                
                {authLoggedUser?.keyword_position_access && (
                    <button id="keyword-position-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/seo/keywordposition/list')}>
                    <MdRocketLaunch className={` size-6 ${!showSideBar && 'size-8'}`} />
                    <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Keyword Position</span>
                </button>
                )}
                {authLoggedUser?.web_scrap_access && (
                    <button id="organic-keyword-sidemenu" className="text-md w-full px-3 py-2 flex items-center gap-2.5 hover:bg-brand-500 text-column hover:text-white dark:bg-navy-900 dark:text-white rounded-md" onClick={() => navigate('/web/scraper')}>
                        <MdWebhook className={` size-6 ${!showSideBar && 'size-8'}`} />
                        <span className={`text-sm  ${!showSideBar && 'hidden'}`}>Web Scraper</span>
                    </button>
                )}
            </div>
        </div>
    )
}


export default AsideNav