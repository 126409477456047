/* eslint-disable no-useless-escape */
import Card from 'components/card'
import TagsField from 'components/fields/TagsField'
import { setCampaignProspectsAddManuallyEmailList } from 'store/campaign'
import { useAppDispatch, useAppSelector } from 'store'
import React from 'react'
const CampaignProsPects = () => {
    // const[url,setUrl]=React.useState([])
    const dispatch = useAppDispatch();
    const campaign = useAppSelector((state) => state?.campaign)

    const handleUpdateEmailList = (type: string, emailList: Array<object>) => {
        const email_list = emailList.map((item: any) => {
            return { id: item?.id, email: item?.name }
        })
        if (type === 'add_manually') {
            dispatch(setCampaignProspectsAddManuallyEmailList(email_list))
        }
    }
    return (
        <div className='flex justify-center items-center my-10'>
            <div className='2xl:w-[45%] sm:w-[80%]'>
                <Card extra={"w-full mt-3 px-6 pt-5"}>
                    {/* Header */}
                    <div className="w-full px-2">
                        <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                            Validation Input
                        </h4>
                    </div>


                    <div className="pt-7 pl-2 w-[50%] mt-[30px] border-t flex flex-col justify-between item-center gap-4 outline-none dark:!border-white/10 sm:w-[94%] md:w-[94%]">
                        <h4 className="text-[11px] flex items-center font-bold text-navy-700 dark:text-white">
                            Add Prospects Url
                        </h4>

                        {/* <div className='flex item-center gap-4 outline-none dark:!border-white/10  md:flex-row lg:flex-row flex-col'>
                            <button onClick={() => setDrawerToggle(true)} className="text-[11px] text-nowrap rounded-xl bg-brand-500 px-9 text-white
                             transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                Import From CSV
                            </button>
                            <button className="text-[11px] text-nowrap rounded-xl bg-brand-500 px-[26px] py-[9px] text-white
                             transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                From LinkedIn Search
                            </button>
                        </div> */}
                    </div>
                    {/* <UploadCSV  onChange={(value) => handleChange('url_list', value)}/> */}
                    <div className="mt-5 px-2 outline-none dark:!border-white/10 sm:w-full md:w-full">
                        <h4 className="text-[11px]  font-bold text-navy-700 dark:text-white">
                            Add Prospects Url Manually
                        </h4>
                        <div className="mt-1 col-span-1  row-span-2  h-full">
                            <TagsField
                                id="description"
                                className="pb-24 pt-4"
                                onChange={(item: any) => handleUpdateEmailList('add_manually', item)}
                                placeholderTags={campaign?.campaignProspectsAddManuallyEmailList?.map(({ email: name, ...rest }: any) => ({ ...rest, name }))}
                                pattern={/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/}
                            />
                        </div>

                    </div>

                    {/* Header */}
                    {/* <div className="w-full px-2 mt-10">
                        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                            Do Not Send Setup
                        </h4>
                    </div>

                    <div className="px-2 py-3 mt-3 outline-none dark:!border-white/10 sm:w-full md:w-full">
                        <h4 className="text-[11px] flex items-center font-bold text-navy-700 dark:text-white">
                            Do Not Send To
                        </h4>
                        <div className='py-3'>
                            <div className="flex ml-2 w-max gap-8 col-span-1">
                                <div className="flex gap-2">
                                    <input type="radio" id="unverified" name='not-send' />
                                    <label htmlFor="unverified" className="cursor-pointer select-none text-[11px] flex items-center font-bold text-navy-700 dark:text-white">
                                        Unverified Email
                                    </label>
                                </div>
                                <div className="flex gap-2">
                                    <input type="radio" id="risky" name='not-send' />
                                    <label htmlFor="risky" className="cursor-pointer select-none text-[11px] flex items-center font-bold text-navy-700 dark:text-white">
                                        Risky
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="pb-6 px-2 py-3 mt-3 outline-none dark:!border-white/10 sm:w-full md:w-full">
                        <h4 className="text-[11px] mb-1 flex items-center font-bold text-navy-700 dark:text-white">
                         Keywords
                        </h4>
                        <TagsField
                            id="description"
                            className="pb-12 pt-4"
                            onChange={(item: any) => { }}
                            placeholderTags={campaign?.campaignProspectsDonotEmailList?.map(({ email: name, ...rest }: any) => ({ ...rest, name }))}
                           
                        />
                    </div>

                </Card>

            </div>
        </div>
    )
}

export default CampaignProsPects