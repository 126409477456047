import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from 'components/navbar';
import { useNavigate } from 'react-router-dom';
import { SessionStorage } from 'common/api/common/storage';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import AsideNav from './Aside';

export default function HomeLayout() {
  const [showSideBar, setShowSideBar] = React.useState<boolean>(true);
  const [navbarName, setNavbarName] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToHome = React.useCallback(() => {
    if (!SessionStorage.getString(LOGIN_STORAGE_VAR)) {
      navigate('/sign-in', { replace: true });
    }
  }, [navigate]);

  React.useEffect(() => {
    switch (location.pathname) {
      // case '/rtl/email/settings':
      //   setNavbarName('Email Accounts');
      //   break;
      case '/seo/list':
        setNavbarName('Seo List');
        break;
      // case '/email/verification/list':
      //   setNavbarName('Email Verifier');
      //   break;
      // case '/email/verification/create' :
      //   setNavbarName('Email Verifier');
      //   break;
      // case '/email/verification/result':
      //   setNavbarName('Email Verifier');
      //   break;
      // case '/domain':
      //   setNavbarName('Domains');
      //   break;
      default:
        setNavbarName('Settings');
    }
  }, [location.pathname]);

  React.useEffect(() => {
    navigateToHome();
  }, [navigateToHome]);
  // React.useEffect(() => {
  //   SessionStorage.setString(LOGIN_STORAGE_VAR, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2MzMyZGZiMTEzNjk2N2U0MDc4MGE2MSIsInV1aWQiOiJlYTY4Y2Y4Ny0zYTIzLTRjMmUtOGY4Zi02YzllMDc5MWE4YmIiLCJmaXJzdF9uYW1lIjoiU2VvIiwibGFzdF9uYW1lIjoiQ2hlY2siLCJlbWFpbCI6InNlby10ZXN0QGdtYWlsLmNvbSIsIm1vYmlsZSI6Ijk5NDQ3NjIxMzYiLCJhdXRoVG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKMWMyVnlYM1YxYVdRaU9pSmxZVFk0WTJZNE55MHpZVEl6TFRSak1tVXRPR1k0WmkwMll6bGxNRGM1TVdFNFltSWlMQ0owWlc1aGJuUmZhV1FpT2lJM056VXpNVE0zTUMwd09EUmhMVEV4WldZdE9EUXhNQzA1TlRrNU5qQmlNbVF4TjJNaUxDSnBZWFFpT2pFM01UVXhORGM0T0Rjc0ltVjRjQ0k2TVRjeE5UYzFNalk0TjMwLmwyV0pZUWpMWHB5ajgtZXE3MmpxS3ZnR3lxb1V2NS1jTTRVcjI1UFllTlUifQ.FagdRkCDcMcI5-K7sIUAeVQ4ySlzBwJrbd05773fW4g');
  // }, []);
  return (
    <div className="flex h-screen w-full overflow-hidden bg-gray-50 p-3 pb-0 dark:bg-background-900">
      {<AsideNav showSideBar={showSideBar} setShowSideBar={setShowSideBar} />}
      <div className="ml-3 h-screen w-full dark:bg-navy-900">
        <main className={`overflow-y-auto transition-all dark:bg-navy-900`}>
          {/* Header - Navbar */}
          <Navbar navbarName={navbarName} />
          {/* Content - Body */}
          <Outlet />
        </main>
      </div>
    </div>
  );
}
