import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal"
import { BaseApi } from "common/api/common/base-api"
import { toastState } from "common/utils/utility"
import CustomBadge from "components/badges/CustomBadge"
import InputField from "components/fields/InputField"
import { useState } from "react"
import { toast } from "react-toastify"
import { useAppSelector } from "store"

const TestEmail = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [inputData, setInputData] = useState({to: ''})
    const campaign = useAppSelector(state => state?.campaign)
    const handleTestMail = async ()=>{
        const testData = {
            sender_email_id : campaign?.campaignSettingSenderEmailList[0],
            to_emails : inputData.to.split(','),
            subject : campaign?.campaignSetupList[0]?.version_a?.subject,
            message : campaign?.campaignSetupList[0]?.version_a?.message
        }
        try{
            const response = await BaseApi.post('/campaign/sent/test/mail', testData)
            onClose()
            toast.success(response?.message, toastState.success) 
            console.log(testData)
        }
        catch(err){
            console.log(err)
        }
    }
    return (
        <>
            <button id="test-btn" onClick={onOpen}>
                <CustomBadge
                    content={`Send Test E-Mail`}
                    color={''}
                    bgColor={`indigo-50`}
                    extra='w-full font-medium text-sm py-3 border'
                />
            </button>
            <Modal isOpen={isOpen} onClose={onClose} size={'3xl'}>
                <ModalOverlay />
                <ModalContent className="!bg-blue-50 !rounded-xl">
                    <ModalHeader>Send a Test Email to</ModalHeader>
                    <ModalCloseButton className='text-blue-600 !text-md' />
                    <ModalBody>
                        <div className="bg-white pt-8 px-8 pb-16 rounded-md">
                            <InputField id={"testmail"} label={"Send a Test Email to"} placeholder={"Sabrina83@gmail.com"} type='email'
                            onChange={(e: any)=>setInputData({to : e?.target?.value})}/>
                            <h4 className='text-xs text-gray-600 mt-3 ml-1'>Use commas to separate multiple emails</h4>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <button onClick={onClose} className="border w-3/12 p-2 border-gray-500 text-sm text-gray-600 active:bg-blue-100 duration-300 rounded-xl mx-4">
                            Cancel
                        </button>
                        <button id="send-test-btn" onClick={handleTestMail} className="border w-3/12 p-2 text-white bg-brand-500 text-sm  active:bg-brand-600 duration-300 rounded-xl">Send test</button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default TestEmail