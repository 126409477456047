
const Message = ({ emailDetails }: any) => {
  const { subject, message, to } = emailDetails
  const dataURL = `data:text/html;charset=UTF-8,${encodeURIComponent(message)}`;
  const subjectCont = `data:text/html;charset=UTF-8,${encodeURIComponent(subject)}`;
  return (
    <div className='bg-white rounded-xl border py-8 mb-6'>
      <div className='flex bg-purple-100 border px-4 py-2 gap-3'>
        <span className='text-gray-700 font-semibold text-sm w-full truncate text-center'>To : {to.name} &lt;{to.email}&gt;</span>
      </div>

      <div className=' px-10'>
        <div className='flex gap-2 items-center mt-10'>
          {/* <h5 className='font-semibold text-md'>{subjectCont}</h5> */}
          <iframe
            title="Subject Content"
            src={subjectCont}
            width="100%"
            className='h-16'
          />
        </div>

        <iframe
          title="HTML Content"
          src={dataURL}
          width="100%"
          className='h-96 htmliframe'
        />
      </div>
    </div>
  )
}

export default Message