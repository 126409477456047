import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

const ArrowedTooltip = (props: {
    data?: string | ReactJSXElement;
    className?: string;
    toolTipId?: string
}) => {
    const { data, className, toolTipId } = props
    return <div className={`absolute top-7 z-10 -left-6 w-28 bg-[#2B3674] rounded-md text-center p-1 ${className}`}>
        <div className={`z-0 absolute -top-1 left-1/2 -translate-x-1/2 size-3 bg-[#2B3674] rotate-45`}></div>
        <h5 className="relative z-10 font-thin text-[9px] text-white" id={toolTipId}>{data}</h5>
    </div>
}


export default ArrowedTooltip