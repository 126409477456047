import { CloseSvg } from 'assets/img/campaign';
import { BaseApi } from 'common/api/common/base-api';
import { SessionStorage } from 'common/api/common/storage';
import { EDIT_ID } from 'common/constants/constants';
import Stepper from 'components/stepper';
import { ReactSVG } from 'react-svg';
import { useAppDispatch, useAppSelector } from 'store';
import { clearOldCampaign, setCurrentHeaderSteps, updateCampaignName } from 'store/campaign';
import { setFlagStatus } from 'store/custom';
import { toast } from "react-toastify"
import { toastState } from "common/utils/utility"
import { useRef, useState } from 'react';

const CampaignHeader = () => {
    const dispatch = useAppDispatch();
    const campaign = useAppSelector((state: { campaign: any; }) => state?.campaign);
    const inputRef = useRef(null)
    const [disableLaunch, setDisableLaunch] = useState(false)
    const handleLaunchCampaign = async () => {
        const editId = SessionStorage.getString(EDIT_ID);
        if (campaign?.headerSteps.length === campaign?.currentHeaderStep && editId) {
            if(campaign?.campaignSetupList.length === 0 ||
                campaign?.campaignSetupList[0].version_a.subject === "" ||
                campaign?.CampaignScheduleAndTrackingData.time_schedule_id === undefined){
               toast.error('Create Sequence and select schedule to launch', toastState.error)
              console.log(
                'ssss',  campaign?.CampaignScheduleAndTrackingData.time_schedule_id
              ) 
           }
           else{
            setDisableLaunch(true)
            try {
                const data = await BaseApi.post('/campaign/launch', { uuid: campaign?.customCampaignID || editId });
                if (data?.error) {
                    console.log('error', data?.message)
                    setDisableLaunch(true)
                }          
                 else {
                        dispatch(clearOldCampaign(campaign))
                        toast.success('Campaign added success', toastState.success);
                        SessionStorage.clearItem(EDIT_ID);
                        dispatch(setFlagStatus(false));          
                }
            } catch (error) {
                console.error(error)
            }}
        } else {
            if (campaign?.currentHeaderStep >= 4) return
            dispatch(setCurrentHeaderSteps(campaign?.currentHeaderStep + 1))
        }
    }

    return (
        <div className="header-2 2xl:h-min lg:h-36 sm:h-36 shadow-lg shadow-gray-100 z-10">
            <nav className="bg-white w-full">
                <div className="px-11  mx-auto 2xl:flex md:items-center md:justify-between 2xl:pt-0 sm:pt-5 w-full">
                    <div className="flex border w-[20%] rounded items-center gap-1">
                        <button id='campaign-close-btn' onClick={() => { 
                            dispatch(setFlagStatus(false)); SessionStorage.clearItem(EDIT_ID);
                            dispatch(clearOldCampaign(campaign))
                            }}>
                            <ReactSVG src={CloseSvg} className='scale-[0.8]' />
                        </button>
                        <h3 className="relative font-bold text-[19px] text-gray-800">
                            <input ref={inputRef} id='campaign-name' value={campaign?.campaignName} onFocus={e => e.target.select()} type="text" placeholder='Client Url Name'
                                className='w-full select-all outline outline-0 cursor-pointer' onChange={(e) => dispatch(updateCampaignName(e.target.value))} />
                        {/* <MdModeEdit onClick={()=> inputRef.current.focus()}
                className='cursor-pointer absolute -right-5 top-1 text-gray-700'/> */}
                        </h3>
                    </div>
                    <div className='2xl:w-[38%] sm:w-full 2xl:-ml-16 xm:ml-0 pt-[20px] pb-[48px]  text-brand-700 text-xs'>

                        <Stepper
                            steps={campaign?.headerSteps}
                        />
                    </div>
                    <div className='flex float-right h-[38px] 2xl:top-0 sm:-top-32 2xl:mt-0 lg:mt-3 relative gap-3'>
                        
                        <button disabled ={disableLaunch} onClick={() => handleLaunchCampaign()} className="shadow-lg shadow-brand-100 rounded-md bg-brand-500 px-[4.4rem] text-[12.8px] text-white transition duration-200 
                        hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                            {campaign?.headerSteps.length === campaign?.currentHeaderStep ? 'Launch' : 'Next'}
                        </button>
                    </div>
                </div>
            </nav>
        </div>

    )
}

export default CampaignHeader