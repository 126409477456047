
type ButtonProps = {
  name ?: string;
  color ?: string;
  bg ?: string;
  LeftIcon ?: React.JSX.Element;
  RightIcon ?: React.JSX.Element;
  px ?: string;
  py ?: string;
  fontSize ?: string;
  onClick ?: ()=> void;
  customClass ?: string;
}

const Button = (props: ButtonProps) => {
  const {color, bg, LeftIcon, RightIcon, name, px, py, fontSize, onClick, customClass} = props

  return (
      <button className={`text-${color || 'white'} bg-${bg || 'brand-500'} text-${fontSize || '[0.7rem]'} rounded-md ${py || 'py-2'} ${px || 'px-2.5'} border flex gap-1 items-center justify-center hover:filter hover:drop-shadow-md ${customClass}`} onClick={onClick}>
       {LeftIcon && LeftIcon}
       {name}
       {RightIcon && RightIcon}
        </button>   
  )
}

export default Button