import { useState } from "react"
import { GrNext, GrPrevious } from "react-icons/gr"
import { IoMdTime } from "react-icons/io"
import { MdAdd, MdModeEdit } from "react-icons/md"
import { RiDeleteBinLine } from "react-icons/ri"
import ScheduleDrawer from "./ScheduleDrawer"
import DeleteScheduleDrawer from "./ScheduleDeleteDrawer"
import React from "react"
import { BaseApi } from "common/api/common/base-api"
import { useAppDispatch, useAppSelector } from "store"
import { setCampaignScheduleAndTrackingData } from "store/campaign"

const ScheduleComponent = () => {
    const dispatch = useAppDispatch();
    const campaign = useAppSelector(state => state?.campaign)
    const [cardActive, setCardActive] = useState<string>(campaign?.CampaignScheduleAndTrackingData?.time_schedule_id)
    type schedulerType = {
        clicked: boolean, title: string, schedule_id : string
    }
    const [editState, setEditState] = useState<schedulerType>({
        clicked: false,
        title: '',
        schedule_id : ''
    })
    type deleteType = {
        clicked: boolean, title: string, schedule_id : string
    }
    const [deleteState, setDeleteState] = useState<deleteType>({
        clicked: false,
        title: '',
        schedule_id : ""
    })
    const [scheduleList, setScheduleList] = useState([])
    const fetchList = async () => {
        try {
            const response = await BaseApi.get('/time/schedule');
            if (response?.data && response?.data?.length) {
                setScheduleList(response?.data)
                console.log('data', response?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    React.useEffect(() => {
        fetchList();
    }, [editState?.clicked])


    // const campaignData = [
    //     {
    //         id: 1,
    //         date: "03",
    //         day: "Wed",
    //         campaign: "Schedule 1",
    //         time: "6PM EST"

    //     },
    //     {
    //         id: 2,
    //         date: "12",
    //         day: "Fri",
    //         campaign: "Schedule 2",
    //         time: "6PM EST"

    //     },
    //     {
    //         id: 3,
    //         date: "03",
    //         day: "Wed",
    //         campaign: "Schedule 1",
    //         time: "6PM EST"

    //     },
    //     {
    //         id: 4,
    //         date: "12",
    //         day: "Fri",
    //         campaign: "Schedule 2",
    //         time: "6PM EST"

    //     }
    // ]

    const handleEditClick = (event: any, campaignName: any, schedule_id: string) => {
        // Add functionality for the edit action here
        event.stopPropagation(); // Stop event propagation to prevent triggering cardActive functionality
        setEditState((prev) => ({
            clicked: true,
            title: campaignName,
            schedule_id
        }))
    };

    const handleDeleteClick = (event: any, scheduleName: any, schedule_id : string) => {
        // Add functionality for the edit action here
        event.stopPropagation(); // Stop event propagation to prevent triggering cardActive functionality
        setDeleteState(() => ({
            clicked: true,
            title: scheduleName,
            schedule_id
        }))

    };

    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide(currentSlide === -scheduleList.length + 2 ? 0 : currentSlide - 1);
    };

    const prevSlide = () => {
        setCurrentSlide(currentSlide === 0 ? 0 : currentSlide + 1);
    };

    const handleSelectTimeSchedule = (uuid: string) => {
        setCardActive(uuid);
        dispatch(setCampaignScheduleAndTrackingData({ time_schedule_id: uuid }))
    }

    return (
        <div className="w-full flex items-center flex-col px-4">
            <div className='w-full flex items-center'>
                <button className={`border p-2 rounded-full duration-300 text-gray-600 ${currentSlide === 0 ? 'cursor-default' : 'hover:bg-brand-500 hover:text-white hover:border-brand-500 hover:shadow-brand-200 active:bg-brand-600 shadow-md'}`} onClick={prevSlide}>
                    <GrPrevious className='text-sm font-bold' />
                </button>
                <div className="w-11/12 py-4 px-2 overflow-x-hidden">
                    {/* Your carousel content here */}
                    <div className={`flex gap-2 duration-500 `} style={{ transform: `translateX(${currentSlide * 250}px)` }}>
                        <div id="add-new-schedule" className='border-2 border-dashed border-gray-700 w-44 cursor-pointer hover:scale-105 duration-300 rounded-xl flex gap-3 items-center p-4'
                            onClick={(event) => { setCardActive(null); handleEditClick(event, 'New Schedule' , "") }}>
                            <div className='w-8 h-8 rounded-md text-2xl text-white bg-brand-800 items-center flex-none flex flex-col justify-center'>
                                <MdAdd />
                            </div>
                            <h4 className='font-bold text-sm text-gray-600'>New schedule</h4>
                        </div>
                        {
                            scheduleList.map((element, index) => (
                                <div className={`shadow-lg shadow-gray-100 cursor-pointer duration-300 hover:scale-105 rounded-2xl ${cardActive === element?.uuid ? 'border border-brand-300 bg-blue-100' : 'border'} flex p-3`} key={index} onClick={() => handleSelectTimeSchedule(element?.uuid)}>
                                    <div className={`${element.name} w-40 flex flex-col gap-1 justify-center pl-4`}>
                                        <h4 className={`text-md text-nowrap font-bold w-36 truncate`}>{element.name}</h4>
                                        <div className={`flex gap-1 items-center text-xs ${cardActive === element?.uuid ? 'text-brand-500' : 'text-gray-600'}`}>
                                            <IoMdTime className='mb-0.5 text-sm' />
                                            <h4 className="text-md text-gray-800">{`
                                            ${element.start_time.hours}:${element.start_time.minutes}
                                            ${element.start_time.hours < 12 ? 'AM': 'PM'} to 
                                            ${element.end_time.hours}:${element.end_time.minutes}
                                            ${element.end_time.hours < 12 ? 'AM': 'PM'}`}</h4>
                                        </div>
                                    </div>
                                    <div className=' flex gap-1 items-center pb-1 pl-2  '>

                                        <MdModeEdit className='text-gray-700 hover:cursor-pointer hover:scale-125 hover:ease-in-out hover:duration-300' onClick={(event) => handleEditClick(event, element.campaign, element.uuid)} />
                                        <RiDeleteBinLine className='text-gray-700 hover:cursor-pointer hover:scale-125 hover:ease-in-out hover:duration-300' onClick={(event) => handleDeleteClick(event, element.name, element.uuid)} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <button id="next-schedule" className='border p-2 rounded-full duration-300 active:bg-brand-600 shadow-md text-gray-600 hover:shadow-brand-200 hover:border-brand-500 hover:bg-brand-500 hover:text-white' onClick={nextSlide}>
                    <GrNext className='text-sm font-bold' />
                </button>
            </div>
            {/* Scheduler Component */}
            {editState.clicked && <ScheduleDrawer editState={editState} setEditState={setEditState} />}
            {/* Confirmation for delete */}
            {<DeleteScheduleDrawer deleteState={deleteState} setDeleteState={setDeleteState} setScheduleList = {setScheduleList}/>}
        </div>
    )
}

export default ScheduleComponent;