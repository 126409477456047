// DTO - Data Transfer Object

export const SigninFormData = {
    email: '',
    password: '',
    remember: false,
};

export const SignupFormData = {
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    password: '',
    confirm_password: '',
};