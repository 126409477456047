import { MdChevronRight } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from 'store';
import { setCampaignSetupList, setSideMenuToggle } from 'store/campaign';

const SideMenu = () => {
  const campaignState = useAppSelector((state: { campaign: any; }) => state?.campaign);
  const disaptch = useAppDispatch();
  const parentchildBtnClassName = "group/parent w-full mt-3 linear flex flex-row justify-between rounded-xl bg-indigo-50 hover:bg-brand-500 hover:text-white  px-5 py-2 font-medium text-blueSecondary transition duration-200  active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";
  const subChildBtnClassName = "z-50 mb-3 w-full linear flex flex-row justify-between rounded-xl bg-indigo-50 hover:text-white px-5 py-3 font-medium text-gray-800 transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200";

  const handleAddCampaignSetup = () => {
    disaptch(setCampaignSetupList())
    disaptch(setSideMenuToggle(false));
  }

  return (
    <div className={`${((campaignState?.sideMenuToggle) && (campaignState?.currentHeaderStep === 2)) || "hidden"}
     fixed 2xl:top-24 sm:top-32 left-7 z-50 2xl:w-1/6 md:w-fit-content p-5 px-4 mt-5 rounded-2xl
      shadow-2xl shadow-gray-700/50 bg-white flex flex-col justify-around`}>

      {/* Button */}
      <div id='email-btn' className="group/task relative w-full linear flex flex-row justify-between rounded-xl bg-indigo-50 hover:text-white px-5 py-2 font-medium text-blueSecondary transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        <h5 className='text-xs mt-1 font-extrabold'>Email</h5>
        <MdChevronRight className="text-2xl text-gray-700 group-hover/task:text-white" />
        {/* Child Channel Opened*/}
        <div className={`group/platform w-min-content p-5 pb-3 flex-row justify-between absolute translate-x-full -top-5 right-0 rounded-2xl shadow-2xl shadow-gray-700/50 bg-white hidden group-hover/task:block `}>
          {/* Button */}
          <button id='create-btn' onClick={() => handleAddCampaignSetup()}
            className={subChildBtnClassName}>
            <h5 className='text-xs font-extrabold -ml-2'>Create</h5>
          </button>
          <button id='template-btn' className={subChildBtnClassName}>
            <h5 className='text-xs font-extrabold -ml-2 text-nowrap'>Select from template</h5>
          </button>
        </div>
        {/* Child Channel Closed */}
      </div>
      {/* Button */}
      <button className={parentchildBtnClassName}>
        <h5 className='text-xs mt-1 font-extrabold'>Triggers</h5>
        <MdChevronRight className="text-2xl text-gray-700 group-hover/parent:text-white" />
      </button>
    </div>
  )
}

export default SideMenu