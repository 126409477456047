import React, { useState } from 'react'
import { IoMdMore } from 'react-icons/io'
import {  MdOutlineCloudDownload } from 'react-icons/md'
import { saveAs } from 'file-saver';
// import { BaseApi } from 'common/api/common/base-api';
interface RowData {
    id?: string;
    uuid?: any;
    info?: any

}
// interface csvTempData {
//     campaign_name: any,
//     cdate: any,
//     created_by: any,
//     campaign_status: any,
//     campaign_progress_step_percent: any,
//     campaign_delivery_message_data: any
// }

const RowSettingsKeywordPosition = (props: RowData) => {
    const { info } = props
    // const campaign = useAppSelector(state => state?.campaign)
    // const custom = useAppSelector(state => state?.custom)
    const [showOptions, setShowOptions] = useState(false)
    // const [data, setData] = useState([])


    const downloadCsv = (res: any) => {
        const csvContent = convertArrayOfObjectsToCSV(res);
        // console.log(csvContent);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'Seo_Keyword_position_search.csv');
    };

    const convertArrayOfObjectsToCSV = (array: any): string => {
        if (array.length === 0) {
            return '';
        }
        const headers = Object.keys(array[0]).join(',');
        const rows = array.map((obj: any) => Object.values(obj).join(','));
        return [headers, ...rows].join('\n');
    };

    const handleOptions = (selectedOpt: string,) => {
        if (selectedOpt === 'download') {
            // fetchData()
            const client_url_name = info?.original?.client_url_name;

            let res = info?.original?.url_list?.flatMap((item: any) => {
                const prospect_url = item?.url;

                return item?.result?.map((resultItem: any) => ({
                    client_url_name,
                    prospect_url,
                    keyword: resultItem.keyword,
                    // best_position_url: resultItem.best_position_url,
                    best_position: resultItem.best_position
                }));
            }).filter(Boolean) || [];

            

            // console.log(res, "RES CSV")
            downloadCsv(res)
            setShowOptions(false)


        }
    }

    return (
        <>
            <div className={`relative cursor-pointer rounded-md w-min py-0.5 mr-3 ${showOptions && 'bg-[#4318FF0A]'}`} id='seo-validation-list-download-btn' tabIndex={0} onBlur={() => setShowOptions(false)}>
                <IoMdMore className="text-lg text-gray-700 z-0" onClick={() => setShowOptions(true)} />
                {(showOptions) && <div className="z-10 border rounded-md flex flex-col bg-white absolute -left-[75px] overflow-hidden">

                    <div id='seo-v-download-btn' className='group flex gap-2 hover:bg-brand-500 hover:text-white transition-all duration-150 font-medium px-3 py-2 cursor-default campaign-download-status-btn' onClick={() => handleOptions('download')}>
                        <MdOutlineCloudDownload className='text-brand-500 group-hover:text-white' />
                        <h5 className="text-xs">Download</h5>
                    </div>
                </div>}
            </div>
            {/* {custom?.globalOpt && <GlobalOpt handleOptions={handleOptions} />} */}
        </>

    )
}
export default RowSettingsKeywordPosition