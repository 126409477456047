import React from 'react';
import { FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { BaseApi } from 'common/api/common/base-api';
import { useAppDispatch, useAppSelector } from 'store';
import  { setFlagStatus } from 'store/custom';

interface PauseResumeProps {
  uuid: string;
  status: number;
}

const PauseResume: React.FC<PauseResumeProps> = ({ uuid, status }) => {
  const dispatch =useAppDispatch()
  const flagStatus= useAppSelector((state)=>state?.custom?.flagStatus)
  const [pause, setPause] = React.useState(false);
  const newStatus = status===2 ? 'resume' : 'pause';
React.useEffect(()=>{
 if(status===2){
  setPause(true)
 }else{
  setPause(false)
 }
},[status])
  const handlePauseResume = async () => {
    try {
      setPause(!pause);

      const webScrapPauseResponse = await BaseApi.patch(
        `/web/scrap/status/update`,
        {
          uuid: uuid,
          status: newStatus,
        }
      );

      if (!webScrapPauseResponse?.error) {
        toast.success(`${status===2 ? 'Resumed' : 'Paused'} Successfully`);
        dispatch(setFlagStatus(!flagStatus))
      }
    } catch (error) {
      console.error('Error from PauseResume component:', error);
      toast.error('An error occurred while trying to change the status');
    }
  };
  return (
    <div>
      <button
        className={`${
          pause ? 'bg-green-300' : 'bg-yellow-300'
        } mr-1 gap-0.5 rounded-md px-2 py-1.5`}
        onClick={handlePauseResume}
      >
        {pause ? (
          <FaPlayCircle className="size-5 cursor-pointer text-gray-800" />
        ) : (
          <FaPauseCircle className="size-5 cursor-pointer text-gray-800" />
        )}
      </button>
    </div>
  );
};

export default PauseResume;
