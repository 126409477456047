import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import LogoCard from "../components/LogoCard";
import SignupWithGoogle from "../components/SignupWithGoogle";
import GoogleOAuthContainer from "components/google-oauth";
function SignIn() {

    // const dispatch = useAppDispatch();
    // const { errorMessage }: any = useAppSelector((state: { custom: any; }) => state.custom);
    // const [formData, setFormData] = useState<SigninFormDTO>(SigninFormData);
    // const handleFormChange = (e: { target: { id: any; value: any; }; }): void => setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    // const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        // e.preventDefault();
    //     try {

    //         const data = await BaseApi.post('/auth/login', formData);
    //         if (data?.error) {
    //             dispatch(getErrorMessage(data?.message))
    //         } else {
    //             SessionStorage.setString(LOGIN_STORAGE_VAR, data?.data);
    //             dispatch(setLoggedUserDetails(jwtDecrypt(data?.data)));
    //         }
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }
    return (
        <div>
            <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
                <main className={`mx-auto min-h-screen`}>
                    
                    <FixedPlugin />
                    
                    <LogoCard
                        maincard={
                            
                            <div className=" flex h-screen  w-full items-center justify-center px-2 md:mx-0 md:px-0  lg:items-center lg:justify-start">
                                {/* Sign in section */}
                                <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                                {/* <h1 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">SEO</h1> */}

                                    <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                                        Sign In
                                    </h3>
                                    <br />
                                    

                                    <GoogleOAuthContainer source={<SignupWithGoogle text="Sign In with Google" />} />

                                    
                                </div>
                            </div>
                        }
                    />
                </main>
            </div>
        </div>
    );
}

export default SignIn;
