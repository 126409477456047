/* eslint-disable @typescript-eslint/no-explicit-any */
import instance from 'common/api/common/instance.axios';
import { AxiosRequestConfig } from 'axios';

export class BaseApi {
    public static async get(url: string, params?: Record<string, any>): Promise<any> {
        try {
            const result = await instance.get(url, {
                params,
                data: params,
            });

            return result;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    public static async post(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
        try {
            const result = await instance.post(url, params, config);
            return result
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    public static async put(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
        try {
            const result = await instance.put(url, params, config);
            return result;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    public static async patch(url: string, params: Record<string, any>, config?: AxiosRequestConfig): Promise<any> {
        try {
            const result = await instance.patch(url, params, config);
            return result;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    public static async delete(url: string, config?: AxiosRequestConfig): Promise<any> {
        try {
            const result = await instance.delete(url, config);
            return result;
        } catch (error) {
            return await Promise.reject(error);
        }
    }
}
