/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from 'store';
import { setFlagStatus } from 'store/custom';
import CampaignHeader from './campaign-header';
import CampaignProsPects from './campaign-prospects';
import CampaignSettings from './campaign-settings';
import CampaignScheduleTracking from './campaign-schedule-tracking';
import Scaler from './campaign-setup/flow-diagram/Scaler';
import CampaignSetUpDiagram from './campaign-setup/flow-diagram';
import SideMenu from './campaign-setup/flow-diagram/SideMenu';
import { useEffect } from 'react';
// import { BaseApi } from 'common/api/common/base-api';
import { EDIT_ID } from 'common/constants/constants';
import { SessionStorage } from 'common/api/common/storage';
// import { updateCampaignSetupList } from 'store/campaign';
import CampaignFinalReview from './campaign-review';
import { randomNumber } from 'common/utils/utility';
// import { setCurrentHeaderSteps } from 'store/campaign';
// import { toast } from 'react-toastify';

const CampaignCreatePage = () => {
    const custom = useAppSelector((state: any) => state?.custom);
    const campaign = useAppSelector((state: any) => state?.campaign);
    const editId = SessionStorage.getString(EDIT_ID);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const campaignSetupData = campaign?.campaignSetupList.map((item: object, index: number, array: any) => {
            if (index === 0) {
                return { ...item, delay: { days: '0', hours: '0', minutes: '0' } }
            } else {
                return { ...item, delay: { days: array[index - 1]?.delay?.days, hours: array[index - 1]?.delay?.hours, minutes: array[index - 1]?.delay?.minutes } }
            }
        })
        const formData = {
            uuid: editId,
            campaign_name: campaign?.campaignName || `Campaign ${randomNumber(1000, 9999)}`,
            campaign_setup: campaignSetupData,
            prospects: {
                add_manually_email_list: campaign?.campaignProspectsAddManuallyEmailList,
                donot_email_list: campaign?.campaignProspectsDonotEmailList,
                upload_csv_data: campaign?.campaignProspectsEmailListUploadCSVData,
                upload_csv_name_count: campaign?.campaignProspectsEmailListUploadCSVNameAndCount,
                upload_csv_email_list: campaign?.campaignProspectsEmailListUploadCSVFinalData,
                enrichment: campaign?.campaignProspectsEmailListUploadCSVEnrichmentData,
                total_email_list: [...campaign?.campaignProspectsAddManuallyEmailList, ...campaign?.campaignProspectsEmailListUploadCSVFinalData],
            },
            campaign_settings: {
                sender_emails: {
                    email_list: campaign?.campaignSettingSenderEmailList,
                    group_list: campaign?.campaignSettingSenderEmailGroupsList,
                }
            },
            schedule_and_tracking: campaign?.CampaignScheduleAndTrackingData,
            campaign_progress_step: campaign?.currentHeaderStep -1
        }
        if(campaign?.customCampaignID) formData.uuid = campaign?.customCampaignID

    //     const validationCampaignData = () => {
    //         const { currentHeaderStep, campaignProspectsEmailListUploadCSVFinalData, campaignProspectsAddManuallyEmailList, campaignSetupList, CampaignScheduleAndTrackingData, campaignSettingSenderEmailList } = campaign;
    //         let status = false
    //         switch (currentHeaderStep) {
    //             case 2:
    //                 if (!(campaignProspectsEmailListUploadCSVFinalData?.length || campaignProspectsAddManuallyEmailList?.length)) {
    //                     toast.error('At least upload CSV file or add manual email list', toastState.error);
    //                     dispatch(setCurrentHeaderSteps(1));
    //                 } else {
    //                     dispatch(setCurrentHeaderSteps(2));
    //                     status = true
    //                 }
    //                 break;

    //             case 3:
    //                 if (!campaignSetupList?.length) {
    //                     toast.error('At least create one sequence', toastState.error);
    //                     dispatch(setCurrentHeaderSteps(2));
    //                 } else {
    //                     dispatch(setCurrentHeaderSteps(3));
    //                     status = true
    //                 }
    //                 break;

    //             case 4:
    //                 const isScheduleAvailable = CampaignScheduleAndTrackingData?.hasOwnProperty('time_schedule_id');
    //                 if (!(isScheduleAvailable && campaignSettingSenderEmailList?.length)) {
    //                     toast.error('At least select one schedule and one sender', toastState.error);
    //                     dispatch(setCurrentHeaderSteps(3));
    //                 } else {
    //                     dispatch(setCurrentHeaderSteps(4));
    //                     status = true
    //                 }
    //                 break;

    //             default:
    //                 break;
    //         }

    //         return status
    //     }

    //     if (validationCampaignData()) {
    //         const createAndUpdateCampaign = async () => {
    //             try {
    //                 const data = await BaseApi.post('/campaign/create', formData);
    //                 if (data?.error) {
    //                     console.log('error', data?.message)
    //                 } else {
    //                     SessionStorage.setString(EDIT_ID, data?.data?.uuid);
    //                     // dispatch(updateCampaignSetupList(data?.data?.campaign_setup));
    //                 }
    //             } catch (error) {
    //                 console.error(error)
    //             }
    //         }
    //         createAndUpdateCampaign();
    //     }
    }, [campaign?.currentHeaderStep])
    // useEffect(() => {
    //     const fetchList = async () => {
    //         try {
    //             const response = await BaseApi.get(`/campaign/${editId}`);
    //             if (!response?.error && response?.data?.uuid) {
    //                 const result = response?.data;
    //                 console.log(result)
    //             }
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     if (editId) {
    //         fetchList();
    //     }
    // }, [editId])

    return (
        <div>
            <Modal onClose={() => dispatch(setFlagStatus(false))} size={'full'} isOpen={custom?.flagStatus}>
                <ModalOverlay />
                <ModalContent zIndex={10}>
                    {/* Header section */}
                    <CampaignHeader />
                    {/* Side menu section */}
                    <SideMenu />
                    {/* Body section */}
                    <div className="overflow-auto">
                        <div className='relative'>
                            <ModalBody bg={campaign?.currentHeaderStep === 1 ?
                                'white' : '#F4F7FE'} zIndex={0}>
                                {campaign?.currentHeaderStep === 1 && <CampaignProsPects />}
                                {/* Settings  */}
                                {/* setup section */}
                                {campaign?.currentHeaderStep === 2 &&
                                    <>
                                        <div className='flex justify-center relative -top-2'>
                                            <CampaignSetUpDiagram width={"3/4"} />
                                        </div>
                                        <Scaler />
                                    </>
                                }
                                {/* Prospects section */}
                                {campaign?.currentHeaderStep === 3 &&
                                    <div className='mt-12 lg:w-[50%] sm:w-4/5 rounded-xl bg-white mx-auto'>
                                        <CampaignSettings />
                                        <CampaignScheduleTracking />
                                    </div>}
                                {/* Review & launch*/}
                                {campaign?.currentHeaderStep === 4 && <CampaignFinalReview />}
                                {/* Schedule and  tracking*/}
                                {/* {campaign?.currentHeaderStep === 4 && <CampaignScheduleTracking />} */}
                            </ModalBody>
                        </div>
                    </div>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default CampaignCreatePage