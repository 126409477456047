import TableLoader from 'components/Loaders/TableViewLoader';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';

import Chart from 'react-apexcharts';
import { RiInformationFill } from 'react-icons/ri';
// import { GiCheckMark } from "react-icons/gi";
import { MdVerifiedUser } from "react-icons/md";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
// import AnalysisModal from '../';
import React, { useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { EDIT_ID } from 'common/constants/constants';
import {
  EmailVerifierResultRowObject,
  StatisticsEntry,
} from 'common/dto/types/views/email-verifier';
// import ChartSkeliton from './ChartSkeliton';
// import EmailVerificationExportOptions from './EmailVerificationExportOptions';
import { BsStars } from 'react-icons/bs';
import Button from 'common/SparkleCommon/Button';
import { PiArrowArcLeftThin } from 'react-icons/pi';
// import TrueVerifierModel from './TrueVerifierModel';

type BulletPropType = {
  content: string
}

const BulletPoints = (props: BulletPropType) => {
  const { content } = props
  return (
    <div className='flex gap-2 items-center'>
      <div className='text-textGreen bg-bgGreen flex justify-center items-center w-5 h-5 rounded-full'>
        {/* <GiCheckMark size={11} /> */}
      </div>
      <span className='text-column text-sm'>{content}</span>
    </div>
  )
}

const CampaignSetUpDiagram = (props: {
  width: string;
  preview?: boolean;  
}) => {
    const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(true);
  const [chartData, setChartData] = useState<StatisticsEntry[]>();
  const [series, setSeries] = useState([]);
  // True verifier model state
  const [isTrueVerifierOpen, setIsTrueVerifierOpen] = useState(false)
  console.log(isTrueVerifierOpen)

  const [isOpen, setIsOpen] = React.useState({
    show: false,
    resultUUID: '',
  });
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });

  const [columns, setColumns] = React.useState([]);

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const columnHelper = createColumnHelper<EmailVerifierResultRowObject>();
  const columnData = [
    columnHelper.accessor('email', {
      id: 'email',
      enableSorting: false,
      header: () => (
        <div className="select-none truncate font-semibold text-blackDark">
          Email Accounts
        </div>
      ),
      cell: (info) => (
        <div>
          {
            <p
              className={`pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}
            >
              {info.getValue()}
            </p>
          }
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      enableSorting: false,
      header: () => (
        <div className="relative select-none font-semibold text-blackDark">
          Status
        </div>
      ),
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    columnHelper.accessor('result', {
      id: 'result',
      header: () => (
        <div className="relative select-none font-semibold text-blackDark">
          Result
        </div>
      ),
      cell: (info) => {
        return (
          <div>
            {info.getValue() === 'Valid' ? (
              <div className="flex w-[80px] items-center justify-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1.5">
                <FaCheck className="text-xs font-bold text-[#12B76A]" />
                <h5
                  className={`text-xs font-semibold text-[#027A48] status-index-${info?.row?.id}`}
                >
                  Valid
                </h5>
              </div>
            ) : info.getValue() === 'Invalid' ? (
              <div className="flex w-[80px] items-center  justify-center gap-1 rounded-full bg-[#FEF6EE] px-2 py-1.5">
                <IoClose className="text-xs font-bold text-[#EF6820]" />
                <h5
                  className={`text-xs font-semibold text-[#B93815] status-index-${info?.row?.id}`}
                >
                  Invalid
                </h5>
              </div>
            ) : info.getValue() === 'Catch-All' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#EEF4FF] px-2 py-2">
                <IoClose className="text-xs text-[#6172F3]" />
                <h5
                  className={`text-xs font-semibold text-[#3538CD] status-index-${info?.row?.id}`}
                >
                  Catch All
                </h5>
              </div>
            ) : info.getValue() === 'Unknown' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#FFFAEB] px-2 py-2">
                <IoClose className="text-xs text-[#F79009]" />
                <h5
                  className={`text-xs font-semibold text-[#B54708] status-index-${info?.row?.id}`}
                >
                  Unknown
                </h5>
              </div>
            ) : info.getValue() === 'Error' ? (
              <div className="flex w-[90px] items-center justify-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1.5">
                <IoClose className="text-xs text-[#F04438]" />
                <h5
                  className={`text-xs font-semibold text-[#B42318] status-index-${info?.row?.id}`}
                >
                  Error
                </h5>
              </div>
            ) : null}
          </div>
        );
      },
    }),
  ];
  React.useEffect(() => {
    const fetchData = async () => {
      const listId = sessionStorage.getItem(EDIT_ID);
      if (listId) {
        try {
          const response = await BaseApi.get(`/email/verify/list/${listId}`);
          const data = response.data.email_list.map((item: any) => ({
            email: item.email,
            status: item?.verify_details?.status?.result
              ? 'Processed'
              : 'Processing...',
            result:
              item?.verify_details?.status?.result === 'verify_success'
                ? 'Valid'
                : item?.verify_details?.status?.result === 'catch_all_enabled'
                  ? 'Catch-All'
                  : item?.verify_details?.status?.result === 'timeout' ||
                    item?.verify_details?.status?.result ===
                    'greylist_detected' ||
                    item?.verify_details?.status?.result === 'tarpitting_detected'
                    ? 'Unknown'
                    : item?.verify_details?.status?.result === 'temp_mail' ||
                      item?.verify_details?.status?.result === 'domain_failed' ||
                      item?.verify_details?.status?.result === 'smtp_failed' ||
                      item?.verify_details?.status?.result === 'verify_failed'
                      ? 'Invalid'
                      : '',
            multiResult: item?.verify_details?.multi_status,
          }));
          setIsProcessing(
            data?.some((d: any) => d?.status === 'Processing...')
          );
          setData({
            data: data,
            count: response.data.email_list,
          });

          setColumns(columnData);
          const statistics = [
            {
              count:
                response?.data?.email_statistics_details
                  ?.email_verify_success_count || 0,
              color: '#14B8A6',
              label: 'Valid',
            },
            {
              count:
                response?.data?.email_statistics_details
                  ?.email_invalid_email_count +
                response?.data?.email_statistics_details
                  ?.email_temp_mail_count +
                response?.data?.email_statistics_details
                  ?.email_domain_failed_count +
                response?.data?.email_statistics_details
                  ?.email_smtp_failed_count +
                response?.data?.email_statistics_details
                  ?.email_verify_failed_count || 0,
              color: '#F59E0B',
              label: 'Invalid',
            },
            {
              count: 0,
              color: '#F50B0B',
              label: 'Error',
            },
            {
              count:
                response?.data?.email_statistics_details
                  ?.email_graylist_detected_count +
                response?.data?.email_statistics_details
                  ?.email_verify_timeout_count +
                response?.data?.email_statistics_details
                  ?.email_tarpitting_detected_count || 0,
              color: '#FACC15',
              label: 'Unknown',
            },
            {
              count:
                response?.data?.email_statistics_details
                  ?.email_catch_all_enabled_count || 0,
              color: '#6366F1',
              label: 'Catch All',
            },

          ];


          setSeries(statistics.map((item) => item.count));
          setChartData(statistics);
          setListData(data);
          setLoading(false);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      }
    };
    fetchData();
    const intervalId = setInterval(() => {
      if (isProcessing) {
        fetchData();
      } else {
        fetchData();
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  React.useEffect(() => {
    if (isOpen?.show) {
      // const initialData = {
      //   isValidSyntax: 'Verification pending',
      //   isValidDomain: 'Verification pending',
      //   isTempEmail: 'Verification pending',
      //   isRoleBasedAccount: 'Verification pending',
      //   isCatchAllDomain: 'Verification pending',
      //   isValidSmtp: 'Verification pending',
      //   isValidEmail: 'Verification pending',
      //   isTarpitting: 'Verification pending',
      //   isGreyListed: 'Verification pending',
      // };

      const multiResult = listData?.filter(
        (ele) => ele?.email === isOpen?.resultUUID
      )[0];
      delete multiResult?.mxRecord;

      // const values = multiResult?.multiResult
      //   ? multiResult?.multiResult

      
      //   : initialData;
      // const resultArray = Object.keys(values).map((key) => ({
      //   key,
      //   value: values[key as keyof EmailValidationResult],
      // }));
      // const resultArray = Object.keys(values)
      //   .filter((key) => key !== 'mxRecord') // Exclude 'mxRecord' from the keys
      //   .map((key) => ({
      //     key,
      //     value: values[key as keyof EmailValidationResult], // Corrected type assertion for clarity
      //   }));

      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen?.show, listData]);

  const options = {
    labels: ['Valid', 'Invalid', 'Error', 'Unknown', 'Catch All'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%', // Responsive width
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    colors: ['#14B8A6', '#F59E0B', '#F50B0B', '#FACC15', '#6366F1', '#C9D0E3'],
    plotOptions: {
      pie: {
        donut: {
          size: '48%',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
  };

  return (
    <div
      className={`shadow-sm mt-2.5 w-full rounded-lg bg-white pt-2.5 dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}
    >
      <div className="relative mb-3 flex items-center justify-end py-1 sm:gap-32 md:px-10 lg:gap-32 lg:px-3">
        <div
          className="flex items-center gap-2"
        >
          {/* <EmailVerificationExportOptions
            listData={listData}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
          /> */}
        </div>
      </div>

      <div className="h-[79vh] border-t">
        {!listData?.length ? (
         '' // <ChartSkeliton />
        ) : (
          <div
            className={`shadow-sm m-2.5 flex rounded-md border border-[#e0ccfc] bg-[#fbfaff] dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}
          >
            <div className="flex h-full w-80 justify-center pl-4 pt-2 ">
              <Chart
                options={options}
                series={
                  series?.some((d: any) => d !== 0)
                    ? series
                    : [0, 0, 0, 0, 0, 1]
                }
                type="donut"
                width={410}
              />
            </div>

            <div className="flex h-full w-full flex-col justify-center gap-2 p-5 align-middle ">
              <div className="flex gap-2">
                <h4 className="text-[1.5rem] font-bold text-heading">
                  Total - {listData?.length}
                </h4>
                <RiInformationFill className="my-auto text-xl text-[#8F9BBA]" />
              </div>
              <div className="flex gap-[4.3125rem] align-middle">
                {chartData?.map((val) => (
                  <div className="flex gap-2 items-center ml-3 w-28" key={val?.label}>
                    <span
                      className="h-3 w-3 rounded-full"
                      style={{ background: val?.color }}
                    />
                    <p className="text-lg font-bold text-heading">
                      {val?.label}
                    </p>
                  </div>
                ))}
              </div>
              <div className="flex gap-20 border-y bg-white p-3 align-middle">
                {chartData?.map((value, index) => (
                  <div className="flex gap-3 w-28 pl-1" key={index}>
                    <p className="text-2xl font-bold text-heading">
                      {value?.count || 0}
                    </p>
                  </div>
                ))}
              </div>
              {/* Sparkle verifier box */}
              <div className='relative pt-10'>
                <div className='border border-blueSecondary rounded-xl p-4 bg-white flex flex-col gap-3'>
                  <div className='flex justify-between'>
                    <div className='flex items-center gap-2 text-xl font-bold text-heading '>
                      <BsStars />
                      <h4>Sparkle Verifier</h4>
                    </div>
                    <Button name='Start Verification'
                      LeftIcon={<MdVerifiedUser size={15} />}
                      onClick={() => setIsTrueVerifierOpen(true)} />
                  </div>
                  <div className='flex gap-4 '>
                    <BulletPoints content='Prevents bounces' />
                    <BulletPoints content='Removes duplicates' />
                    <BulletPoints content='Helps protect the deliverability & reputation of the domain' />
                  </div>
                </div>
                <div className='text-heading absolute top-3 left-28'>
                  <span className='font-bold text-xs'>Most popular!</span>
                  <div className='w-9 overflow-hidden absolute top-0 -left-10'>
                    <PiArrowArcLeftThin size={50} className='-rotate-45' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!loading ? (
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="px-3">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={`mb-2 w-1/3 border-y border-gray-200 bg-gray-50 px-3 py-4 text-start text-column dark:border-white/10 dark:bg-navy-900`}
                      >
                        <div className="text-[14px]">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y-[1px]">
              {data?.data?.length ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className="hover:bg-hover dark:hover:bg-navy-900"
                    >
                      {row.getVisibleCells().map((cell, i, arr) => {
                        return (
                          <td
                            key={cell.id}
                            className={`cursor-pointer border-white/0 px-3 py-3 text-start text-sm font-semibold text-heading dark:text-white`}
                            onClick={
                              () =>
                                setIsOpen({
                                  show: true,
                                  resultUUID: row?.original?.email,
                                })
                              // handleListClick({
                              //   email: row.original.email,
                              //   multiResult: row?.original?.multiResult,
                              // })
                            }
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr></tr>
              )}
            </tbody>
          </table>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        )}

        

       
        
      </div>
    </div>
  );
  
}

export default CampaignSetUpDiagram