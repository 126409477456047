import { FaUser } from "react-icons/fa";

function Chat(props: {
    photo ?: string;
    active?: number;
    name: string;
    time?: string;
    message: string;
    last?: boolean;
    handleProspectClick?:any;
    index?:any
  }) {
    const { photo, active, name, time, message, last, handleProspectClick,index } = props;
  
    return (
      <div
      onClick={()=>handleProspectClick(index)}
        className={`group flex w-full duration-500 items-center gap-1 px-3 ${
          last ? "pt-6 pb-3" : "border-b border-gray-100 py-5"
        }  cursor-pointer dark:!border-navy-700 lg:cursor-pointer ${active === index ? 'bg-indigo-200' : 'hover:bg-indigo-100'}`}
      >
        <div className="relative h-[50px] !w-[60px] rounded-full">
            {photo ? 
          <img className="h-full w-full rounded-full" src={photo} alt="" />:
          <div className="bg-gray-100 w-10 h-10 flex items-center justify-center border rounded-full">
              <FaUser className={`text-lg duration-500 ${active === index ? 'text-blue-600' : 'text-gray-400'} group-hover:text-blue-600`}/>
          </div>
            }
        </div>
  
        {/*  */}
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <h1 id={`preview-user-${name}`} className={`text-md -mt-2 duration-500 font-bold capitalize ${active === index ? 'text-blue-600' : 'text-gray-700'} group-hover:text-blue-600 dark:text-white`}>
              {" "}
              {name}{" "}
            </h1>
            <h4 className="text-sm font-medium text-gray-400">{time} </h4>
          </div>
          <p className="group-hover:block w-36 text-xs font-normal tracking-normal mt-3 text-navy-700 dark:text-white truncate">
            {message}{" "}
          </p>
        </div>
      </div>
    );
  }
  
  export default Chat;
  