import { useState } from "react";
import Dropdown from "components/dropdown";
import { FiMoon, FiSun } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { SessionStorage } from "common/api/common/storage";
import { setLoggedUserDetails } from "store/authentication";
import { Avatar } from "@chakra-ui/react";
import Toggle from "components/Toggle";
import { RxPerson } from "react-icons/rx";
import { MdLogout } from "react-icons/md";

interface NavProps{
  navbarName ?: string;
}
const Navbar = (props: NavProps) => {
  const navbarName = "SEO Validation"
  // const [langOptions, setLangOptions] = React.useState(false)
  // const [selectedLang, setSelectedLang] = React.useState({ lang: 'EN', imgSrc: ENsvg })
  const navigate = useNavigate();
  const loggedUser = useAppSelector((state) => state?.auth?.loggedUser);
  const dispatch = useAppDispatch();
  // const searchRef = useRef(null)
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  const handleLogout = () => {
    SessionStorage.clear();
    dispatch(setLoggedUserDetails())
    navigate('/sign-in')
  }
  const toggleDarkMode = () => {
    if (isDarkMode) {
      document.body.classList.remove('dark');
      setIsDarkMode(false);
    } else {
      document.body.classList.add('dark');
      setIsDarkMode(true);
    }
  };

  // const languages = [
  //   { lang: 'EN', imgSrc: ENsvg },
  //   { lang: 'FR', imgSrc: FRsvg },
  //   { lang: 'DE', imgSrc: DEsvg },
  //   { lang: 'US', imgSrc: ESsvg },
  // ]
  // let mini = false, hovered = true
  // const breadcrumbData = [
  //   { label: 'Home', href: '/' },
  //   { label: 'Projects', href: '/projects' },
  //   { label: 'Project Nero', href: '/projects/nero' }
  // ];
  return (
    <nav className={`duration-175 relative flex flex-wrap py-2.5 items-center shadow-sm justify-between rounded-lg bg-white dark:text-white dark:bg-navy-800 transition-all`}
    >
      <div className="relative w-full flex items-center lg:justify-between lg:gap-0 md:gap-3 px-5 py-0.5">
      <h5 className="font-bold text-heading dark:text-white">{navbarName}</h5>
        {/* Profile & Dropdown */}
        <div className="flex items-center gap-5">
         
          
          <Toggle className="scale-75" changeFunction={toggleDarkMode} checkedCondition={isDarkMode} Icon1={FiSun} Icon2={FiMoon} />
          

          <Dropdown
          
            button={
              <Avatar height={'32px'} width={'32px'} name={`${loggedUser?.first_name} ${loggedUser?.last_name}`} bg='teal.500' src={loggedUser?.picture} />
            }
            children={
              <div className="flex h-max w-fit flex-col mt-3 justify-start rounded-md bg-white bg-cover bg-no-repeat shadow-md shadow-gray-200 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="my-3 mx-4 ">
                  <div className="flex items-center gap-2">
                    <Avatar name={`${loggedUser?.first_name} ${loggedUser?.last_name}`} bg='teal.500' src={loggedUser?.picture} className="!h-9 !w-9" />
                    <div className="flex flex-col gap-0.5">
                      <p className="text-xs font-bold text-navy-700 dark:text-white">
                        {`${loggedUser?.first_name} ${loggedUser?.last_name}`}
                      </p>
                      <p className="font-bold text-gray-600 text-xs dark:text-white w-36 truncate">
                        {loggedUser?.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col border-t">
                    <div className="cursor-pointer duration-300 text-xs flex items-center gap-2 px-4 p-2 text-gray-800 hover:bg-gray-50 dark:text-white hover:dark:text-white">
                      <RxPerson size={15} />
                      <span>
                        Account Settings
                      </span>
                    </div>
                  <div id="account-fucntion-logout-btn" className="flex gap-2 items-center duration-300 cursor-pointer border-t px-4 p-2 text-gray-800 text-xs hover:bg-gray-50" onClick={handleLogout}>
                    <MdLogout size={15}/>
                    <span>
                      Log Out
                    </span>
                  </div>
                  {/* <span className="cursor-pointer text-sm dark:text-white hover:dark:text-white" onClick={() => { navigate('/rtl/email/settings'); }}> Profile Settings </span>
              <span className="cursor-pointer mt-3 text-sm dark:text-white hover:dark:text-white"> Newsletter Settings </span>
              <span className="cursor-pointer mt-3 text-sm font-medium text-red-500 hover:text-red-500" onClick={() => handleLogout()}> Log Out </span> */}
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>

      </div>
    </nav>
  );
};

export default Navbar;
