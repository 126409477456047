/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd, MdOutlineRocketLaunch } from 'react-icons/md';
import TableLoader from 'components/Loaders/TableViewLoader';
import InfiniteScrollComponent from 'components/infinityScroll';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { setAndGetQueryParams } from 'common/utils/utility';
import { BaseApi } from 'common/api/common/base-api';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import Card from 'components/card';
import TagsField from 'components/fields/TagsField';
import { FaCloudDownloadAlt, FaRunning } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import moment from 'moment';
import PauseResume from './feature/PauseResume';
import { useAppSelector } from 'store';

const WebScraperList = () => {
  const flagStatus= useAppSelector((state)=>state?.custom?.flagStatus)

    const columnWidth = ['w-[4%]', 'w-[18%]', 'w-[9%]', 'w-[10%]', 'w-[15%]', 'w-[15%]', 'w-[10%]', 'w-[10%]', 'w-[10%]'];
    const [data, setData] = React.useState({
        data: [],
        count: 0,
        loading: false,
    });
    const [queryParams, setQueryParams] = React.useState({
        limit: 25,
        offset: 0,
        sort: { columnName: '', order: '' },
        search: '',
        filter: '',
    });
    // const debouncedSearchValue = useDebounce(queryParams?.search, 300);
    React.useEffect(() => {
        const fetchBrandsList = async () => {
            try {
                setLoading(true);
                const webScrapResponse = await BaseApi.get(`/web/scrap`);
                webScrapResponse?.data?.data?.length
                    ? setData({ ...data, data: webScrapResponse?.data?.data, count: webScrapResponse?.data?.total_records?.count })
                    : setData({ ...data, data: [], count: 0 });
                    setQueryParams((prev) => ({
                        ...prev,
                        offset: 2,
                    }));
                // table.resetRowSelection();
                setLoading(false);
            } catch (error) {
                console.error('Error From Brand Table Page Catch block', error);
            }
        };
        fetchBrandsList();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [flagStatus]);

    const handleDownload = async (id: any) => {
        try {
            const response = await BaseApi.post(`/web/scrap/downlaod`, { id });
            const data = response?.data?.data;
            const generateExcel = async () => {
                const processedData: any[] = [];

                data.forEach((item: { url: any; url_scrap_details: any; }) => {
                    const { url, url_scrap_details } = item;
                    if (url_scrap_details.length === 0) {
                        processedData.push({ URL: url, "Sub URL": "", Email: "" });
                    } else {
                        url_scrap_details.forEach((detail: { sub_url: any; email_list: any; }) => {
                            const { sub_url, email_list } = detail;
                            email_list.forEach((email: any) => {
                                processedData.push({ URL: url, "Sub URL": sub_url, Email: email });
                            });
                        });
                    }
                });

                const worksheet = XLSX.utils.json_to_sheet(processedData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

                XLSX.writeFile(workbook, "URL_Details.xlsx");
            };
            await generateExcel()


            // console.log('response', response)
        } catch (error) {
            console.error('handleUrlSubmit Error:', error);
        }
    }

    const [loading, setLoading] = React.useState(false);
    const columnHelper = createColumnHelper<any>();
    const columnData = [

        columnHelper.accessor('id', {
            id: 'id',
            enableSorting: false,
            header: () => <div className="ml-3 text-md">S.No</div>,
            cell: (info) => <h5 className="ml-5 text-[14px] font-bold text-gray-900">{Number(info?.row.id) + 1}</h5>,
        }),

        columnHelper.accessor('title', {
            id: 'title',
            enableSorting: false,
            header: () => <div className="text-md">Title</div>,
            cell: (info) => {
                return (
                    <Popover>
                        <PopoverTrigger>
                            <h5 className="text-[14px] font-bold text-gray-900">{info.getValue()}</h5>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                <Box userSelect="text">
                                    {info?.row?.original?.uuid}
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                )
            },
        }),

        columnHelper.accessor('total_urls_count', {
            id: 'total_urls_count',
            enableSorting: false,
            header: () => <div className='ml-3 text-md'>Total Sites</div>,
            cell: (info) => <h5 className=" ml-7 text-[12px] font-medium text-gray-700">{info.getValue()}</h5>,
        }),

        columnHelper.accessor('update_status_count', {
            id: 'update_status_count',
            enableSorting: false,
            header: () => <div className='ml-3 text-md'>Process Sites</div>,
            cell: (info) => <h5 className=" ml-5 text-[12px] font-medium text-gray-700">{info.getValue()}</h5>,
        }),

        columnHelper.accessor('scrap_status', {
            id: 'scrap_status',
            header: () => <div className="ml-14">Status</div>,
            cell: (info) => {
                return (
                    // <span>{JSON.stringify(info?.row?.original)}</span>)
                    <h5 className=" ml-10 text-[12px] font-medium text-gray-700">{(info?.row?.original?.total_urls_count === info?.row?.original?.update_status_count && info?.row?.original?.scrap_status) ? <><b>Completed</b></> : (info?.row?.original?.scrap_status === 2)? <><b>Paused</b></> :'Processing....'}</h5>)
                // <h5 className=" ml-10 text-[12px] font-medium text-gray-700">{(info.getValue() === 1) ? <><b onClick={() => handleDownload(info?.row?.original?.uuid)}>Completed</b></> : 'Processing....'}</h5>)
            }
        }),

        columnHelper.accessor('created_by', {
            id: 'created_by',
            enableSorting: false,
            header: () => <div className="ml-6">Created By</div>,
            cell: (info) => <h5 className=" ml-5 text-[12px] font-medium text-gray-700">{info?.row?.original?.created_user?.first_name + ' ' + info?.row?.original?.created_user?.last_name}</h5>,
        }),

        columnHelper.accessor('created_by', {
            id: 'modified_by',
            enableSorting: false,
            header: () => <div className="ml-6">Modified By</div>,
            cell: (info) => <h5 className=" ml-5 text-[12px] font-medium text-gray-700">{[(info?.row?.original?.modified_user?.first_name || ''), (info?.row?.original?.modified_user?.last_name || '')].filter(Boolean).join(' ')}</h5>,
        }),

        columnHelper.accessor('cdate', {
            id: 'cdate',
            enableSorting: false,
            header: () => <div className="ml-6">Date</div>,
            cell: (info) => {
                return (<h5 className=" ml-5 text-[12px] font-medium text-gray-700">{calculateTimeAgo(info.getValue())}</h5>)
            },
        }),

        columnHelper.accessor('action', {
            id: 'action',
            header: () => <div className="text-start">Action</div>,
            cell: (info) => {
                // console.log("info",info?.row?.original)
                return (
                    <div className='flex'>
                    <PauseResume uuid={info?.row?.original?.uuid} status={info?.row?.original?.scrap_status}/>
                    <button className="bg-blueSecondary ml-1 gap-0.5 rounded-md bg-brand-500 px-2 py-1.5" onClick={() => handleDownload(info?.row?.original?.uuid)}>
                        <FaCloudDownloadAlt className="size-5 text-white cursor-pointer " />
                    </button>
                    </div>
                    
                    )
            }
        }),
    ];

    function calculateTimeAgo(timerange: string) {
        const timestamp = moment(timerange);
        const now = moment();

        // Calculate differences for each time unit
        const years = now.diff(timestamp, 'years');
        timestamp.add(years, 'years'); // Adjust timestamp after calculating years

        const months = now.diff(timestamp, 'months');
        timestamp.add(months, 'months'); // Adjust timestamp after calculating months

        const weeks = now.diff(timestamp, 'weeks');
        timestamp.add(weeks, 'weeks'); // Adjust timestamp after calculating weeks

        const seconds = now.diff(timestamp, 'seconds');
        const days = now.diff(timestamp.startOf('day'), 'days');
        // Determine and format the output
        if (seconds < 60) return 'just now';
        if (years > 0) return years === 1 ? 'year ago' : `${years} years ago`;
        if (months > 0) return months === 1 ? 'month ago' : `${months} months ago`;
        if (weeks > 0) return weeks === 1 ? 'week ago' : `${weeks} weeks ago`;
        if (days > 0) return days === 1 ? 'yesterday' : `${days} days ago`;
        return 'today';
    }

    const table = useReactTable({
        data: data?.data,
        columns: columnData,
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
    });

    const scrollFunction = React.useCallback(
        async (setIsFetching: any) => {
            const fetchEntryList = async () => {
                try {
                    setIsFetching(true);
                    const query = setAndGetQueryParams([
                        // { key: 'limit', value: queryParams?.limit },
                        { key: 'offset', value: queryParams?.offset },
                        // { key: 'search', value: queryParams?.search },
                        {
                            key: 'sort',
                            value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
                        },
                        { key: 'filter', value: queryParams?.filter },
                    ]);
                    const response = await BaseApi.get(`/web/scrap?${query}`);
                    const responseData: any = response.data;
                    if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
                    if (responseData?.data?.length < 25) {
                        setData((prev) => ({
                            ...prev,
                            data: [...prev?.data, ...responseData?.data],
                            count: responseData?.total_records?.count,
                        }));
                    } else {
                        setData((prev) => ({
                            ...prev,
                            data: [...prev?.data, ...responseData?.data],
                        }));
                    }
                    setQueryParams((prev) => ({
                        ...prev,
                        offset: prev?.offset + 1,
                    }));
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsFetching(false);
                }
            };


            data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
    );
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [modal, setModal] = useState({ formModal: false, helper_modal1: false, helper_modal2: false, })
    const [helperFormData, setHelperFormData] = useState({ title_name1: '', title_name2: '' })
    const [formData, setFormData] = useState({ title: '', url_list: [] })
    const handleChange = (type: string, value: any) => {
        setFormData((prev) => ({ ...prev, [type]: value }))
    }
    const handleUrlSubmit = async () => {
        try {
            setLoading(true);
            setIsButtonDisabled(true)
            const response = await BaseApi.post(`/web/scrap`, formData);
            setLoading(false);
            setIsButtonDisabled(false)
            if (!response?.error) {
                setModal((prev) => ({ ...prev, formModal: false }))
            }
        } catch (error) {
            console.error('handleUrlSubmit Error:', error);
        }
    }

    const handleUrlSpeedHelperSubmit = async (helperType = '') => {
        try {
            setLoading(true);
            setIsButtonDisabled(true)
            const data = (helperType === 'helper1') ? { type: helperType, title_id: helperFormData?.title_name1 } : { type: helperType, title_id: helperFormData?.title_name2 }
            const response = await BaseApi.post(`/web/scrap/speed/helper`, data);
            setLoading(false);
            setIsButtonDisabled(false)
            if (!response?.error) {

                if (helperType === 'helper1') {
                    setModal((prev) => ({ ...prev, helper_modal1: false }))
                }
                if (helperType === 'helper2') {
                    setModal((prev) => ({ ...prev, helper_modal2: false }))
                }
            }
        } catch (error) {
            console.error('handleUrlSubmit Error:', error);
        }
    }
    return (
        <div className=" mt-3 min-h-[88vh] rounded-md bg-white ">
            <div className="-mt-2 flex w-full items-center rounded-tl-lg rounded-tr-lg border-b-2  bg-white px-2 py-4 sm:gap-32 lg:gap-32 xl:justify-between">
                <div className="flex items-center gap-2.5">
                    <div
                        className="ml-2 flex items-center gap-2 rounded-md border px-3 py-1.5 outline outline-0 focus-within:outline-2 focus-within:outline-brand-400 md:w-[13rem] lg:w-[26rem]"
                    >
                        <FiSearch className="size-4 cursor-pointer text-gray-800" />
                        <input
                            type="text"
                            placeholder="Search"
                            id="donot-search-input"
                            className="w-full text-sm  text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
                        />
                    </div>
                    <div>
                        <button
                            onClick={() => setModal((prev) => ({ ...prev, helper_modal1: true }))}
                            id="new-site-validation-btn"
                            className=" bg-textGreen mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            <MdOutlineRocketLaunch className="float-right text-lg" />
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => setModal((prev) => ({ ...prev, helper_modal2: true }))}
                            id="new-site-validation-btn"
                            className=" bg-rejected-color mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            <FaRunning className="float-right text-lg" />
                        </button>
                    </div>
                </div>
                <div className="flex items-center gap-2.5">

                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => setModal((prev) => ({ ...prev, formModal: true }))}
                            id="new-site-validation-btn"
                            className="mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                            <MdAdd className="float-right text-lg" />{' '}
                            <span className="text-nowrap text-[11px]">
                                Web Scrap
                            </span>
                        </button>
                    </div>
                </div>

            </div>
            {!loading ? (
                <>
                    <div className="mb-2  h-[82vh]  overflow-y-auto overflow-x-scroll  scroll-smooth xl:overflow-x-hidden">
                        <table className="w-full ">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header, index) => {
                                            const RowDataWidth = columnWidth;
                                            return (
                                                <th
                                                    key={`${header.id}-${index}`}
                                                    colSpan={header.colSpan}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className={`${RowDataWidth[index]} cursor-default select-none border-b border-gray-100 bg-grayBorder py-3.5 text-start text-column dark:border-white/10 dark:bg-navy-900`}
                                                >
                                                    <div className="text-[12px] font-semibold">
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{
                                                            asc: '',
                                                            desc: '',
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                            <tbody className=" divide-y-[1px] ">
                                {data?.data?.length ? (
                                    table.getRowModel().rows.map((row) => {
                                        return (
                                            <tr key={row.id} className={`cursor-default select-none text-start hover:bg-hover dark:hover:bg-navy-900`}>
                                                {row.getVisibleCells().map((cell, i) => {
                                                    const RowDataWidth = columnWidth;
                                                    return (
                                                        <td key={cell.id} className={`${RowDataWidth[i]} py-3.5 text-gray-700 dark:text-white`}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={9} className="pt-2 text-center text-[12px] font-medium text-gray-700">
                                            No Result Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <InfiniteScrollComponent
                            loadMore={scrollFunction}
                            loader={
                                <TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />
                            }
                        />
                    </div>
                </>
            ) : (
                <TableLoader
                    width="w-full"
                    border="border border-gray-100"
                    column={9}
                    row={3}
                    skeletonHeight="h-2"
                    padding="px-5 py-4"
                    tableViewBg={{ headBg: 'bg-gray-50' }}
                    columnWidth="4% 14% 14% 14% 14% 12% 12% 12% 4%"
                />
            )}
            <>
                <Modal closeOnOverlayClick={false} isOpen={modal?.formModal} onClose={() => setModal((prev) => ({ ...prev, formModal: false }))} size={'xl'} >
                    <ModalOverlay
                        bg='none'
                        backdropFilter='auto'
                        backdropInvert='80%'
                        backdropBlur='2px'
                    />
                    <ModalContent maxW="60%">
                        <ModalHeader>Create your scrap title</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Card extra={'w-full mt-3 px-6 pt-5'}>
                                {/* Header */}
                                <div className="w-full px-2">
                                    <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                                        Web Scraper
                                    </h4>
                                </div>

                                <div className="mt-3 px-2 py-3 pb-6 outline-none dark:!border-white/10 sm:w-full md:w-full">
                                    <h4 className="mb-1 flex items-center text-xs font-bold text-navy-700 dark:text-white">
                                        Title
                                    </h4>
                                    <input
                                        id="title"
                                        className="w-full  rounded-md border p-2 text-sm outline-none"
                                        // value={seoform.client_url_name}
                                        onChange={(event) =>
                                            handleChange('title', event?.target?.value)
                                        }
                                        placeholder="title"
                                    />
                                </div>

                                <div className=" item-center  flex flex-col justify-between gap-4 pl-2 outline-none dark:!border-white/10 sm:w-[94%] md:w-[94%]">
                                    <h4 className="flex items-center text-[11px] font-bold text-navy-700 dark:text-white">
                                        Add Site Url
                                    </h4>
                                </div>

                                <div className="mt-5 px-2 outline-none dark:!border-white/10 sm:w-full md:w-full">
                                    <h4 className="text-[11px]  font-bold text-navy-700 dark:text-white">
                                        Add Site Url Manually
                                    </h4>
                                    <div className="col-span-1 row-span-2  mt-1  h-full">
                                        <TagsField
                                            id="site_url_manually"
                                            className="prospects-input pb-24 pt-4"
                                            onChange={(value: any) => handleChange('url_list', value)}
                                            limit={500}
                                            pattern={
                                                /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
                                            }
                                        />
                                    </div>
                                    {formData?.url_list?.length ? <small>Total url list count: <b>{formData?.url_list?.length}</b></small> : ''}
                                </div>
                            </Card>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                onClick={() => handleUrlSubmit()}
                                mr={3}
                                isLoading={loading}
                                loadingText='Loading'
                                colorScheme='teal'
                                spinnerPlacement='start'
                                disabled={isButtonDisabled}
                            >
                                Submit
                            </Button>
                            <Button onClick={() => setModal((prev) => ({ ...prev, formModal: false }))}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
            <>
                <Modal closeOnOverlayClick={false} isOpen={modal?.helper_modal1} onClose={() => setModal((prev) => ({ ...prev, helper_modal1: false }))} size={'xl'} >
                    <ModalOverlay
                        bg='none'
                        backdropFilter='auto'
                        backdropInvert='80%'
                        backdropBlur='2px'
                    />
                    <ModalContent>
                        <ModalHeader>Accelerate Your Speed</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Card extra={'w-full mt-3 px-6 pt-5'}>
                                {/* Header */}
                                <div className="w-full px-2">
                                    <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                                        Web Scraper
                                    </h4>
                                </div>

                                <div className="mt-3 px-2 py-3 pb-6 outline-none dark:!border-white/10 sm:w-full md:w-full">
                                    <h4 className="mb-1 flex items-center text-xs font-bold text-navy-700 dark:text-white">
                                        Title Name
                                    </h4>
                                    <input
                                        id="title"
                                        className="w-full  rounded-md border p-2 text-sm outline-none"
                                        onChange={(event) =>
                                            setHelperFormData((prev) => ({ ...prev, title_name1: event?.target?.value }))
                                        }
                                        placeholder="title name"
                                    />
                                </div>
                            </Card>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                onClick={() => handleUrlSpeedHelperSubmit('helper1')}
                                mr={3}
                                isLoading={loading}
                                loadingText='Loading'
                                colorScheme='teal'
                                spinnerPlacement='start'
                                disabled={isButtonDisabled}
                            >
                                Submit
                            </Button>
                            <Button onClick={() => setModal((prev) => ({ ...prev, helper_modal1: false }))}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
            <>
                <Modal closeOnOverlayClick={false} isOpen={modal?.helper_modal2} onClose={() => setModal((prev) => ({ ...prev, helper_modal2: false }))} size={'xl'} >
                    <ModalOverlay
                        bg='none'
                        backdropFilter='auto'
                        backdropInvert='80%'
                        backdropBlur='2px'
                    />
                    <ModalContent>
                        <ModalHeader>Obtain More Speed</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Card extra={'w-full mt-3 px-6 pt-5'}>
                                {/* Header */}
                                <div className="w-full px-2">
                                    <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                                        Web Scraper
                                    </h4>
                                </div>

                                <div className="mt-3 px-2 py-3 pb-6 outline-none dark:!border-white/10 sm:w-full md:w-full">
                                    <h4 className="mb-1 flex items-center text-xs font-bold text-navy-700 dark:text-white">
                                        Title Name
                                    </h4>
                                    <input
                                        id="title"
                                        className="w-full  rounded-md border p-2 text-sm outline-none"
                                        onChange={(event) =>
                                            setHelperFormData((prev) => ({ ...prev, title_name2: event?.target?.value }))
                                        }
                                        placeholder="title name"
                                    />
                                </div>
                            </Card>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                onClick={() => handleUrlSpeedHelperSubmit('helper2')}
                                mr={3}
                                isLoading={loading}
                                loadingText='Loading'
                                colorScheme='teal'
                                spinnerPlacement='start'
                                disabled={isButtonDisabled}
                            >
                                Submit
                            </Button>
                            <Button onClick={() => setModal((prev) => ({ ...prev, helper_modal2: false }))}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        </div>
    );
};

export default WebScraperList;
