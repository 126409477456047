/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "assets/css/Plugins.css";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import MainRoutes from 'routes/routes';
import { ChakraProvider } from '@chakra-ui/react'
import ErrorBoundary from "views/home-layout/ErrorBoundary";

const App = () => {
  const [themeApp] = useState<any>({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
  }, [themeApp]);

  return (
    <ErrorBoundary>
      <ChakraProvider>
        <MainRoutes />
        <ToastContainer />
      </ChakraProvider>
    </ErrorBoundary>
  );
};

export default App;
