import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { BaseApi } from 'common/api/common/base-api';
import { SessionStorage } from 'common/api/common/storage';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { jwtDecrypt, jwtEncrypt } from 'common/utils/utility';
import { useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from 'store';
import { setLoggedUserDetails } from 'store/authentication';

const   SignupWithGoogle = ({ text }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const store = useAppSelector((state) => state?.auth?.loggedUser)
    useEffect(() => {
        if (store) {
            navigate('/seo/list')
        }
    }, [navigate, store])

    const continueWithGoogle = useGoogleLogin({
        onSuccess: async response => {
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${response.access_token}` },
            })
            const data = await BaseApi.post('/auth/google/signup', { credential: jwtEncrypt(userInfo?.data) });
            SessionStorage.setString(LOGIN_STORAGE_VAR, data?.data);
            dispatch(setLoggedUserDetails(jwtDecrypt(data?.data)));
        },
        // flow: 'auth-code',
    });
    // const check = async () => {
    //     const userInfo = await axios
    //         .get('https://www.googleapis.com/oauth2/v3/userinfo',
    //             {
    //                 headers: { Authorization: `Bearer ya29.a0AfB_byDFvCfU5HfBiedJ9GssWPN4GBMsvbvndVVqWxhSL99DwSw0QbZqmD2WksqwF4Nik0qiQrAvcS1rwAGen-gnCaubFnDwSHACx6JrcKuGTHIWUKFmom6k9pfp_QV-oHVCVVoIoF6kqrp0nbk9q0k7H_TlEFmjvlHjaCgYKAYcSARMSFQHGX2MiC1xnx9Lks5Hw2bHbO_MqKg0171` },
    //             })
    //     const result = userInfo;
    //     console.log('result', result)
    // }
    // check()
    // 
    // const handleLogin = async (response: any) => {
    //     try {
    //         const data = await BaseApi.post('/auth/google/signup', { credential: response?.credential });
    //         SessionStorage.setString(LOGIN_STORAGE_VAR, data?.data);
    //         dispatch(setLoggedUserDetails(jwtDecrypt(data?.data)));
    //     } catch (error) {
    //         console.error(error)
    //     }
    // };
    return (
        <div onClick={() => continueWithGoogle()} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800 dark:text-white">
            {/* <GoogleOAuthProvider clientId="678556597651-5v1hp8u2vlm82db3ggfl220n29r4c50h.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={(res) => handleLogin(res)}
                    type="standard"
                    theme="outline"
                    size="large"
                    text={text}
                    shape="rectangular"
                    logo_alignment="center"
                    width={'1000'}
                    useOneTap={false}
                />
            </GoogleOAuthProvider> */}
            <div id="google-sign-in-btn"className="rounded-full text-xl">
                <FcGoogle />
            </div>
            <p className="text-sm font-medium text-navy-700 dark:text-white">
                {text}
            </p>
        </div>
    )
}

export default SignupWithGoogle