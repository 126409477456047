import { JWT_SECRET } from 'common/constants/constants';
import { ToastOptions, ToastPosition, toast } from 'react-toastify';
import jwtEncode from 'jwt-encode';
import { jwtDecode } from "jwt-decode";
import { LocalStorage, SessionStorage } from 'common/api/common/storage';
import React from 'react';

export const jwtEncrypt = (data: string | object) => {
  try {
    return jwtEncode(data, JWT_SECRET);
  } catch (error) {
    return null;
  }
}

export const jwtDecrypt = (data: string) => {
  try {
    return jwtDecode(data);
  } catch (error) {
    return null;
  }
}

export const encryptLocalStorage = (key: string, data: string | object): boolean => {
  try {
    const encode = jwtEncrypt(data);
    return LocalStorage.setString(key, encode);
  } catch (error) {
    return false;
  }
}

export const encryptSessionStorage = (key: string, data: string | object): boolean => {
  try {
    const encode = jwtEncrypt(data);
    return SessionStorage.setString(key, encode);
  } catch (error) {
    return false;
  }
}

export const decryptSessionStorage = (key: string) => {
  try {
    const result = SessionStorage.getString(key);
    return jwtDecrypt(result);
  } catch (error) {
    return null;
  }
}
export const formValidation = (dataArr: { [x: string]: any; }) => {

  let flag = false;
  // eslint-disable-next-line no-useless-escape
  const email_regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const pincode = /^[0-9]{6,6}$/;
  const mobile = /^[0-9]{10,10}$/;
  let errArr: any = {};
  for (var i in dataArr) {
    if (dataArr[i] === '') {
      flag = true;
      errArr[i] = i.charAt(0).toUpperCase() + i.slice(1).replace(/_/g, ' ') + ' is required';
    } else if (i === 'email' && dataArr[i] !== '') {
      if (!email_regex.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Please enter a valid Email ID';
      }
    } else if (i === 'mobile' && dataArr[i] !== '') {
      if (!mobile.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Mobile number must contain 10 digits';
      }
    } else if (i === 'pincode' && dataArr[i] !== '') {
      if (!pincode.test(dataArr[i])) {
        flag = true;
        errArr[i] = 'Pincode must be a 6 digit value';
      }
    }
    else if (i === 'password' && dataArr[i].length < 5) {
      flag = true;
      errArr[i] = 'Password length is too short';
    }
    else if (i === 'confirm_password' && dataArr[i] !== dataArr.password) {
      flag = true;
      errArr[i] = "Password and confirm password does n't match";
    }
  }
  return { errorStatus: flag, errorList: errArr };

};

export const validatePassword = (password: string): boolean | string => {
  const hasAlphabet = /[a-zA-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
  const hasDigit = /\d/.test(password);
  if (password.length < 6 || !(hasAlphabet && hasSpecialChar && hasDigit)) {
    return false;
  }
  return true;
};

export const errorMessageFormatter = (errors: any) => {
  // eslint-disable-next-line no-sequences
  return errors.reduce((obj: any, item: any) => (obj[item.property] = item.message, obj), {});
};

export const randomString = (length = 6) => {
  try {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let password = '';
    const array = new Uint32Array(length);
    crypto.getRandomValues(array);
    for (let i = 0; i < length; i++) {
      password += chars[array[i] % chars.length];
    }
    return password;
  } catch (err) {
    return false;
  }
};

export const randomNumber = (min: number, max: number) => {
  try {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  } catch (error) {
    return false
  }
}

export const _sortArray = (arr: string[]) => {
  try {
    return arr
      .filter((name, index) => arr.lastIndexOf(name) === index)
      .sort((a, b) => (a < b ? -1 : 1));
  } catch (err) {
    return false;
  }
};

export const setAndGetQueryParams = (arr: object[]) => {
  try {
    var searchParams = new URLSearchParams();
    arr.forEach((item: any) => {
      searchParams.set(item.key, item.value);
    })
    return searchParams.toString();
  } catch (err) {
    return '';
  }
};

export const _emptyArray = (arr: string[]) => {
  try {
    return Array.isArray(arr) && arr.length === 0 ? true : false;
  } catch (err) {
    return false;
  }
};

export const _emptyObject = (obj = {}) => {
  try {
    return typeof obj === 'object' &&
      !Array.isArray(obj) &&
      obj !== null &&
      Object.keys(obj).length === 0
      ? true
      : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isObject = (obj = {}) => {
  try {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isArray = (arr: string[]) => {
  try {
    return typeof arr === 'object' && Array.isArray(arr) && arr !== null;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const _isStr = (str: {} | undefined) => {
  try {
    return typeof str === 'string' ? true : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const convertArrayToObjectArray = (array: any) => {
  const headers = array[0];
  const data = array.slice(1);

  const result = data.map((row: string[]) => {
    let obj: object = {};
    headers.forEach((header: string, index: number) => {
      obj = { ...obj, [header]: row[index] };
    });
    return obj;
  });

  return result;
}

export const base16Decrypt = (salt: any, encoded: { match: (arg0: RegExp) => any[]; }) => {
  const textToChars = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex: string) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
};

export const base16Encrypt = (salt: any, text: string) => {
  const textToChars = (text: string) => text.split('').map((c: string) => c.charCodeAt(0));
  const byteHex = (n: any) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: number, b: number) => a ^ b, code);

  return text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const toastState: Record<string, ToastOptions> = {
  success: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  info: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  error: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
  warning: {
    position: 'top-center' as ToastPosition,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  },
};


// Custom hook for debouncing
export function useDebounce(value: string | boolean, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
          setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on component unmount)
      return () => {
          clearTimeout(handler);
      };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
}

export const handleCopy = async (valueToCopy:string) => {
  try {
    await navigator.clipboard.writeText(valueToCopy);
    toast.success('Copied to clipboard', toastState.success);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};