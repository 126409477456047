import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SessionStorage } from 'common/api/common/storage';
import { LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { jwtDecrypt } from 'common/utils/utility';

interface AuthUserState {
  loggedUser: any;
}

const initialState: AuthUserState = {
  loggedUser: jwtDecrypt(SessionStorage.getString(LOGIN_STORAGE_VAR)),
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setLoggedUserDetails: (state, action: PayloadAction<object>) => {
      state.loggedUser = action.payload;
    },
  }
});

export const { setLoggedUserDetails } = headerSlice.actions;
export default headerSlice.reducer;
