/* eslint-disable react-hooks/exhaustive-deps */
import Chat from 'components/messages/Chat'
import { useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { useAppSelector } from 'store'

const PreviewAsideLeft = (props: { setEmailDetails: any }) => {
  const { setEmailDetails } = props
  const [viewOptions, setViewOptions] = useState(false)
  const [selected, setSelected] = useState('All items')
  const  campaign  = useAppSelector((state) => state.campaign);
  const [activeProspect, setActiveProspect] = useState(0)
  const [chatList, setChatList] = useState([])

// Merging variables
  const getMergedMessage = (index: any) => {
    const { subject, message } = campaign?.campaignSetupList[0]['version_a']
    const prospect = chatList[index]
    let finalSubject: any = subject
    let finalMessage: any = message
    for (let key in prospect) {
      const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
      finalSubject = finalSubject.replace(regex, prospect[key])
      finalMessage = finalMessage.replace(regex, prospect[key])
    }

    setEmailDetails((prev: any) => ({
      ...prev,
      subject : finalSubject,
      message: finalMessage,
      to: {
        name: prospect?.name,
        email: prospect?.email
      }
    }))
  }

  useEffect(() => {
    setChatList([...campaign?.campaignProspectsEmailListUploadCSVFinalData, ...campaign?.campaignProspectsAddManuallyEmailList])
    getMergedMessage(0)
  }, [campaign?.campaignProspectsEmailListUploadCSVFinalData, campaign?.campaignProspectsAddManuallyEmailList])

  const handleProspectClick = (index: any) => {
    setActiveProspect(index)
    getMergedMessage(index)
  }

  return (
    <div className="w-1/3 ">
      <div className="flex justify-center items-center gap-3 my-7 -ml-10">
        <div className="bg-purple-100 rounded-xl p-2">
          <MdOutlineKeyboardArrowLeft className="text-blue-700 text-xl" />
        </div>
        <h1 className="text-xl font-bold ">Campaign 1</h1>
      </div>
      <hr />
      <div className="flex gap-3 px-3 py-5">
        <div onClick={() => setViewOptions(!viewOptions)}
          className="relative flex w-full cursor-pointer flex-row justify-between items-center rounded-2xl border px-4 py-2">
          <span className="select-none text-sm font-medium">{selected}</span>{viewOptions ? <MdOutlineKeyboardArrowUp className="text-lg" /> :
            <MdOutlineKeyboardArrowDown className="text-lg" />}
          {viewOptions && <div className="z-50 absolute rounded-xl border flex flex-col py-3 gap-2 w-full left-0 top-9 bg-white">
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('All items')}>All items</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Emails')}>Emails</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Calls')}>Calls</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Whatsapp')}>Whatsapp</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Text')}>Text</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Linkedin')}>Linkedin</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Twitter')}>Twitter</span>
            <span className="hover:bg-purple-100 px-4 font-medium select-none"
              onClick={() => setSelected('Snail Mail')}>Snail Mail</span>
          </div>}
        </div>
      </div>
      <div className="flex w-full items-center gap-2 px-2">
        <div className="flex h-[42px] w-full items-center rounded-2xl bg-lightPrimary">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400" />
          </p>
          <input
            type="text"
            placeholder="Search prospects"
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
      </div>
      <div className="mt-3">
        <div className="h-[400px] mb-8 overflow-y-auto">
          {chatList.map((chat, index) => {
            return <Chat
              name={chat.name}
              message={chat.email}
              index={index}
              handleProspectClick={handleProspectClick}
              active={activeProspect}
              key={index}
            />
          })}
        </div>
      </div>
    </div>
  )
}

export default PreviewAsideLeft