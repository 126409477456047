import axios, { AxiosInstance } from 'axios';
import { API_BASEURL, LOGIN_STORAGE_VAR } from 'common/constants/constants';
import { toast } from 'react-toastify';
import { jwtDecrypt, toastState } from 'common/utils/utility';
import { SessionStorage } from './storage';

const instance: AxiosInstance = axios.create({
  baseURL: API_BASEURL,
});

instance.interceptors.request.use(
  request => {
    const state:any = jwtDecrypt(SessionStorage.getString(LOGIN_STORAGE_VAR));
    
    if (state) {
      request.headers.Authorization = 'Bearer ' + state?.authToken;
    }
    request.headers['Client-Path'] = window.location.pathname;
    return request;
  },
  async error => {
    await Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response?.data;
  },
  async error => {

    if (error?.response?.status === 401) {
      toast.error(error?.response?.data?.message || 'Unauthorized', toastState.error);
      sessionStorage.clear();
    } else if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.message || 'Forbidden', toastState.error);
    } else if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.message || 'Data not found', toastState.error);
    } else if (error?.response?.status === 500) {
      toast.error(error?.response?.data?.message || 'Something went wrong', toastState.error);
    } else {
      toast.error(error?.response?.data?.message || 'Something went wrong', toastState.error);
    }
    return error?.response?.data;
  }
);

export default instance;
