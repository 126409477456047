import { GoogleOAuthProvider } from '@react-oauth/google'
import { OAUTH_CLIENT_ID } from 'common/constants/constants'
import React from 'react'

const GoogleOAuthContainer = ({ source }:any) => {
    return (
        <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
            {source}
        </GoogleOAuthProvider>
    )
}

export default GoogleOAuthContainer;