/* eslint-disable-next-line react-hooks/exhaustive-deps*/
/* eslint-disable react-hooks/exhaustive-deps */

import TableLoader from 'components/Loaders/TableViewLoader';
import BackArrow from 'assets/svg/backArrow.svg';
import * as XLSX from 'xlsx';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { SeoRowObject } from 'common/dto/types/views/email-verifier';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { EDIT_ID } from 'common/constants/constants';
import { SessionStorage } from 'common/api/common/storage';
import InfiniteScrollComponent from 'components/infinityScroll';
import { useAppSelector } from 'store';

const SeoValidationResult = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [queryOffset, setQueryOffset] = useState(0);
  const loggerUser = useAppSelector((state) => state.auth.loggedUser);
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [excel_data, setExcel_data] = useState([]);

  const [columns, setColumns] = React.useState([]);
  const columnHelper = createColumnHelper<SeoRowObject>();
  const columnData = [
    columnHelper.accessor('client_url', {
      id: 'client_url',
      enableSorting: false,
      header: () => (
        <div className="w-36  select-none  truncate font-semibold text-blackDark">
          Client Url
        </div>
      ),
      cell: (info) => (
        <div>
          {
            <p
              className={`w-36 truncate  pr-5 text-sm font-semibold row-campaign-${info?.row?.id}`}
            >
              {info.getValue()}
            </p>
          }
        </div>
      ),
    }),
    columnHelper.accessor('prospect_url', {
      id: 'prospect_url',
      enableSorting: false,
      header: () => (
        <div className="relative w-44  select-none truncate font-semibold text-blackDark">
          Prospect Url
        </div>
      ),
      cell: (info) => {
        return (
          <div className={`w-44 truncate  pr-5 text-sm font-semibold `}>
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor('keyword', {
      id: 'keyword',
      enableSorting: false,
      header: () => (
        <div className="relative w-36  select-none font-semibold text-blackDark">
          Keyword
        </div>
      ),
      cell: (info) => {
        console.log("info",info)
        return (
          <div className={`w-40 truncate  pr-5 text-sm font-semibold `}>
            {info.getValue()}
          </div>
        );
      },
    }),
    loggerUser?.site_validation_best_url_access &&
      columnHelper.accessor('best_position_url', {
        id: 'best_position_url',
        enableSorting: false,
        header: () => (
          <div className="relative w-52 select-none truncate font-semibold text-blackDark">
            Best Position Url
          </div>
        ),
        cell: (info) => (
          <div className="w-52 truncate pr-5 text-sm font-semibold">
            {info?.getValue()}
          </div>
        ),
      }),

    // columnHelper.accessor('best_position', {
    //   id: 'best_position',
    //   enableSorting: false,
    //   header: () => (
    //     <div className="relative select-none font-semibold text-blackDark">
    //       Best Position
    //     </div>
    //   ),
    //   cell: (info) => {
    //     return <div>{info.getValue()}</div>;
    //   },
    // }),
  ].filter(Boolean);
  console.log('columnData',columnData)
  const fetchData = async (type: string) => {
    const listId = SessionStorage.getString(EDIT_ID);
    // console.log('listed ID>>', listId);
    if (listId) {
      let response = await BaseApi.get(
        `/seo/${listId}?limit=25&offset=${queryOffset}`
      );
      const responseData =
        type === 'scroll' ? [...data?.data, ...response.data] : response?.data;
      // console.log('responseData', responseData);
      setData({
        data: responseData,
        count: response?.length,
      });
      setColumns(columnData);
      setLoading(false);
    }
  };

  const fetchExcelData = async () => {
    const listId = SessionStorage.getString(EDIT_ID);
    // console.log('listed ID>>', listId);
    if (listId) {
      let response = await BaseApi.get(`/seo/data/${listId}`);
      const responseData = response?.data;
      setExcel_data(responseData);
    }
  };

  const handleScroll = React.useCallback(
    async (setIsFetching: any) => {
      const apicallfunction = async () => {
        try {
          setIsFetching(true);
          setQueryOffset((prev) => prev + 25);
          data?.data?.length && (await fetchData('scroll'));
          // console.log('response');
          // setData((prev) => ({ ...prev, data: [...data?.data, ...response.data] }))
        } catch (err) {
          console.log(err);
        } finally {
          setIsFetching(false);
        }
      };
      data?.data?.length &&
        data?.data?.length < excel_data.length &&
        apicallfunction();
      // data?.data.length !== excel_data.length && apicallfunction();
    },
    [data?.data?.length, queryOffset]
  );
  // console.log('queryOffset', queryOffset);

  React.useEffect(() => {
    fetchData('initial');
    fetchExcelData();
  }, []);

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const handleClick = async (report: any, fileName: string) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(report);
    XLSX.utils.book_append_sheet(wb, ws, 'SEO');
    const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
    const blob = new Blob([wbout], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  return (
    <div
      className={`mt-2.5 w-full rounded-lg bg-white pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}
    >
      <div className="relative mb-3 flex items-center justify-end py-1 sm:gap-32 md:px-10 lg:gap-32 lg:px-3">
        <div className="flex items-center gap-2">
          <button
            id="seo-validation-close-btn"
            onClick={() => {
              navigate('/seo/list');
            }}
          >
            <ReactSVG src={BackArrow} className="scale-[0.8]" />
          </button>
          <button
            id="seo-validation-export-btn"
            className="mx-1 flex items-center justify-center gap-3 rounded-md border border-brand-500 bg-brand-50 px-2 py-1.5 text-sm font-medium text-brand-500 transition duration-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={() => handleClick(excel_data, 'SEO_Keyword_Validation')}
          >
            <FaCloudDownloadAlt className="float-right text-lg" />{' '}
            <span className="text-nowrap text-[14px] font-semibold">
              Export
            </span>
          </button>
        </div>
      </div>

      <div className="h-[79vh] border-t">
        {/*  */}

        {!loading ? (
          <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="px-3">
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className={`mb-2 w-1/5 border-y border-gray-200 bg-gray-50 px-3 py-4 text-center text-column dark:border-white/10 dark:bg-navy-900`}
                        >
                          <div className="text-[14px]">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: '',
                              desc: '',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="divide-y-[1px]">
                {data?.data?.length ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className="hover:bg-hover dark:hover:bg-navy-900"
                      >
                        {row.getVisibleCells().map((cell, i, arr) => {
                          return (
                            <td
                              key={cell.id}
                              className={`cursor-pointer border-white/0 px-3 py-3 text-center text-sm font-semibold text-heading dark:text-white`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
            <InfiniteScrollComponent
              loadMore={handleScroll}
              loader={
                <TableLoader
                  width="w-full"
                  border="border border-gray-100"
                  column={10}
                  row={1}
                  skeletonHeight="h-2"
                  padding="px-5 py-4"
                  columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
                />
              }
            />
          </div>
        ) : (
          <TableLoader
            width="w-full"
            border="border border-gray-100"
            column={10}
            row={13}
            skeletonHeight="h-2"
            padding="px-5 py-4"
            tableViewBg={{ headBg: 'bg-gray-50' }}
            columnWidth="4% 22% 10% 10% 10% 10% 10% 10% 10% 4%"
          />
        )}
      </div>
    </div>
  );
};

export default SeoValidationResult;
