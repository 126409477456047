import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from "@chakra-ui/modal";
import { Button, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuList, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField } from "@chakra-ui/react";
import { BaseApi } from "common/api/common/base-api";
import { timeZones } from "common/dto/initial-value/views/campaign";
import { toastState } from "common/utils/utility";
import React from "react";
import { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { toast } from "react-toastify";

const ScheduleDrawer = ({ editState, setEditState }: any) => {
    const [formData, setFormData] = useState({ name: "", time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone, days: [], start_time: { hours: "", minutes: "00" }, end_time: { hours: "", minutes: "00" } })
    const [timeZone, setTimeZone] = useState([])
    React.useEffect(() => {
        setTimeZone(timeZones)
    }, [])
    React.useEffect(() => {
        const fetchSingleList = async () => {
            try {
                const response = await BaseApi.get(`/time/schedule/${editState.schedule_id}`);
                if (response?.error) {
                    console.log(response?.error)
                }
                else {
                    const { name, time_zone, days, start_time, end_time } = response.data
                    console.log('start_time', start_time.minutes, 'end_time', end_time.minutes)
                    console.log('Schedule data', response.data)
                    setFormData({ name, time_zone, days, start_time, end_time })
                }
            } catch (error) {
                console.log(error)
            }
        }
        if (editState.schedule_id) {
            fetchSingleList();
        }
    }, [editState.schedule_id])
    const weeksList = [{ value: 'mon', label: 'M' }, { value: 'tue', label: 'T' }, { value: 'wed', label: 'W' }, { value: 'thu', label: 'Th' }, { value: 'fri', label: 'F' }, { value: 'sat', label: 'S' }, { value: 'sun', label: 'Sun' }]
    const [menuView, setMenuView] = useState<boolean>(false)
    const inputRef = useRef(null);
    console.log(formData)
    const handleEditIconClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleSubmitSchedule = async () => {
        const { name, start_time, end_time } = formData;
        const { hours: startingHour, minutes: startingMin } = start_time;
        const { hours: endingHour, minutes: endingMin } = end_time;
        if (!name) {
            toast.error('Schedule Name is required', toastState.error);
            return;
        }

        if (parseInt(startingHour) > parseInt(endingHour) || (startingHour === endingHour && parseInt(startingMin) >= parseInt(endingMin))) {
            toast.error('Start time must be earlier than end time', toastState.error);
            return;
        }

        try {
            const data = await BaseApi.post('/time/schedule', formData);
            if (data?.error) {
                console.log('error', data?.message);
                toast.error(data?.message?.time_zone || data?.message?.days, toastState.error);
            } else {
                setEditState({ clicked: false, title: '' });
                setFormData({ name: '', time_zone: '', days: [], start_time: { hours: '', minutes: '' }, end_time: { hours: '', minutes: '' } });
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleSelectMultiDays = (value: string) => {
        let days = formData?.days;
        if (!days.includes(value)) {
            days = [...days, value]
        } else {
            days = days?.filter((item) => item !== value)
        }
        setFormData((prev) => ({ ...prev, days: days }))
    }
    const handleTimeChange = (type: string, interval: string, value: string) => {
        if ((interval === 'hours' && parseInt(value) < 24) || (interval === 'minutes' && parseInt(value) < 60)) {
            if (type === 'Start') {
                let start_time = { [interval]: value.padStart(2, '0') }
                setFormData((prev: any) => ({ ...prev, start_time: { ...prev?.start_time, ...start_time } }))
            }
            if (type === 'End') {
                let end_time = { [interval]: value.padStart(2, '0') }
                setFormData((prev: any) => ({ ...prev, end_time: { ...prev?.end_time, ...end_time } }))
            }
        }
    }

    const handleSearchTimeZone = (event: any) => {
        const { value } = event?.target
        const searchValue = value?.toLowerCase();
        const filteredZones = searchValue
            ? timeZones.filter(zone => zone.toLowerCase().includes(searchValue))
            : timeZones;
        setTimeZone(filteredZones);
    }
    return (
        <Drawer placement="right" onClose={() => { setEditState({ clicked: false, title: '' }); }} isOpen={editState.clicked} size={'md'}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader className='flex gap-3 items-center border-b-2 mx-3'>
                    <input
                        id="schedule-name"
                        ref={inputRef}
                        onFocus={e => { e.target.select() }}
                        placeholder={'Schedule'}
                        type="text"
                        className='w-40 select-all font-bold text-lg mx-1 text-gray-800 outline outline-0 cursor-pointer'
                        value={formData?.name}
                        onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                    />
                    <div
                        className='text-gray-600 p-2 rounded-full bg-gray-100 hover:cursor-pointer hover:scale-105 active:bg-gray-200 duration-300'
                        onClick={handleEditIconClick}
                    >
                        <MdModeEdit />
                    </div>
                </DrawerHeader>
                <DrawerCloseButton className="text-brand-500" />
                <DrawerBody>
                    <div className='mt-16'>
                        {/* Time Zone  */}
                        <h4 className='text-sm font-bold mb-2'>Time Zone</h4>
                        <Menu isOpen={menuView} onClose={() => setMenuView(false)}>
                            <MenuButton as={Button} rightIcon={<RiArrowDropDownLine size={20} className='text-blue-600 select-zone-btn' />} onClick={() => setMenuView(true)} className='!bg-white border w-full py-6 mt-2 !text-start !text-sm !text-gray-800 !rounded-xl hover:!bg-blue-50'>
                                {formData?.time_zone || 'Select time zone'}
                            </MenuButton>
                            <MenuList className='sm:w-[29rem] md:w-[29rem] lg:w-[29rem] xl:w-[29rem] p-2 border border-gray-50 !shadow-lg !shadow-gray-100 max-h-72 overflow-y-auto'>
                                {/* search time zone */}
                                <InputGroup className='mt-4 bg-blue-50 rounded-2xl' onChange={handleSearchTimeZone}>
                                    <InputLeftElement pointerEvents='none' >
                                        <FiSearch className='text-gray-600' />
                                    </InputLeftElement >
                                    <Input type='email' placeholder='Search' className='!rounded-2xl' />
                                </InputGroup>
                                {/* Time zone lists */}
                                <div className='my-3 mt-4 px-3'>
                                    {timeZone.map((element, index) => (
                                        <div onClick={() => { setFormData((prev) => ({ ...prev, time_zone: element })); setMenuView(false) }} key={index} className='my-2 p-2 rounded-md shadow-md border border-gray-100 shadow-gray-100 hover:cursor-pointer duration-300 hover:bg-gray-50 active:bg-gray-100' >
                                            {element}
                                        </div>
                                    ))}
                                </div>
                            </MenuList>
                        </Menu>
                        <div className='border my-8 p-4 rounded-xl shadow-xl shadow-blue-50 border-gray-200'>
                            {/* Day */}
                            <div className='p-2 flex justify-around mx-4'>
                                {/* Day Component */}
                                {weeksList?.map((item: any, index: number) => (
                                    <span id={item?.value} key={index} className={`${formData?.days?.includes(item?.value) ? 'bg-brand-500 text-white' : 'bg-brand-50 text-brand-500'} rounded-lg duration-300 p-2 h-8 w-8 flex items-center justify-center cursor-pointer`}
                                        onClick={() => { handleSelectMultiDays(item?.value); }}>{item?.label}</span>
                                ))}
                            </div>
                            {/* Time */}
                            <div className='flex justify-center gap-8 mt-4'>
                                {/* Time Component for start */}
                                <div className='flex flex-col items-center gap-3 my-4'>
                                    <span className='text-gray-600 font-bold text-sm'>Start</span>
                                    <div className='flex gap-1 items-center '>
                                        <NumberInput
                                            value={formData?.start_time?.hours}
                                            onChange={(value) => handleTimeChange('Start', 'hours', value)}
                                            min={0} max={23} allowMouseWheel className='outline-0 caret-white flex flex-col items-center'>
                                            <NumberIncrementStepper fontSize={10} border={"none"} children={<IoIosArrowUp className="text-brand-700 text-lg" />} />
                                            <NumberInputField p={0}
                                            id="start-hour-inp"
                                                onClick={(e: any) => e.target.select()}
                                                border={0}
                                                height={14}
                                                width={"35px"}
                                                placeholder='00'
                                                className='placeholder-brand-900 py-5 text-brand-900 font-bold text-center focus-visible:!shadow-none'
                                                fontSize={25} />
                                            <NumberDecrementStepper fontSize={10} border={"none"} children={<IoIosArrowDown className="text-brand-700 text-lg" />} />
                                        </NumberInput>
                                        <span className='text-3xl font-bold'>:</span>
                                        <NumberInput
                                            value={formData?.start_time?.minutes}
                                            onChange={(value) => handleTimeChange('Start', 'minutes', value)}
                                            min={0} max={59} allowMouseWheel className='outline-0 caret-white flex flex-col items-center'>
                                            <NumberIncrementStepper fontSize={10} border={"none"} children={<IoIosArrowUp className="text-brand-700 text-lg" />} />
                                            <NumberInputField p={0}
                                            id="start-min-inp"
                                                onClick={(e: any) => e.target.select()}
                                                border={0}
                                                height={14}
                                                width={"35px"}
                                                placeholder='00'
                                                className='py-5 text-brand-900 font-bold text-center focus-visible:!shadow-none'
                                                fontSize={25} />
                                            <NumberDecrementStepper fontSize={10} border={"none"} children={<IoIosArrowDown className="text-brand-700 text-lg" />} />
                                        </NumberInput>
                                    </div>
                                </div>
                                {/* Time Component for end */}
                                <div className='flex flex-col items-center gap-3 my-4'>
                                    <span className='text-gray-600 font-bold text-sm'>End</span>
                                    <div className='flex gap-1 items-center '>
                                        <NumberInput
                                            value={formData?.end_time?.hours}
                                            onChange={(value) => handleTimeChange('End', 'hours', value)}
                                            min={0} max={23} allowMouseWheel className='outline-0 caret-white flex flex-col items-center'>
                                            <NumberIncrementStepper fontSize={10} border={"none"} children={<IoIosArrowUp className="text-brand-700 text-lg" />} />
                                            <NumberInputField p={0}
                                            id="end-hour-inp"
                                                onClick={(e: any) => e.target.select()}
                                                border={0}
                                                height={14}
                                                width={"35px"}
                                                placeholder='00'
                                            className='placeholder-brand-900 py-5 text-brand-900 font-bold text-center focus-visible:!shadow-none'
                                                fontSize={25} />
                                            <NumberDecrementStepper fontSize={10} border={"none"} children={<IoIosArrowDown className="text-brand-700 text-lg" />} />
                                        </NumberInput>
                                        <span className='text-3xl font-bold'>:</span>
                                        <NumberInput
                                            value={formData?.end_time?.minutes}
                                            
                                            onChange={(value) => handleTimeChange('End', 'minutes', value)}
                                            min={0} max={59} allowMouseWheel className='outline-0 caret-white flex flex-col items-center'>
                                            <NumberIncrementStepper fontSize={10} border={"none"} children={<IoIosArrowUp className="text-brand-700 text-lg" />} />
                                            <NumberInputField p={0}
                                            id="end-min-inp"
                                                onClick={(e: any) => e.target.select()}
                                                border={0}
                                                height={14}
                                                width={"35px"}
                                                placeholder='00'
                                                className='py-5 text-brand-900 font-bold text-center focus-visible:!shadow-none'
                                                fontSize={25} />
                                            <NumberDecrementStepper fontSize={10} border={"none"} children={<IoIosArrowDown className="text-brand-700 text-lg" />} />
                                        </NumberInput>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
                <DrawerFooter className='flex !justify-center gap-3 mb-8'>
                    <button className="px-24 text-sm rounded-lg font-bold duration-300 py-2 border text-brand-500 border-brand-500 hover:bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600" >Cancel</button>
                    <button onClick={() => handleSubmitSchedule()} className="px-24 text-sm rounded-lg font-bold duration-300 py-2 border text-white border-brand-500 bg-brand-500 hover:text-white hover:scale-105 active:bg-brand-600">Save</button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default ScheduleDrawer;

