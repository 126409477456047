import { createSlice } from '@reduxjs/toolkit';
import { TrueVerifierState } from 'common/dto/types/views/true-verfier';

const initialState :TrueVerifierState = {
  ticketDetailsStatus : false
}

const trueVerifierSlice = createSlice({
  name: 'true-verifier',
  initialState,
  reducers: {
    showTicketDetailsStatus : (state, action) =>{
      state.ticketDetailsStatus = action.payload
    }
  }
});

export const {showTicketDetailsStatus} = trueVerifierSlice.actions;
export default trueVerifierSlice.reducer;
