
const Toggle = (props: {
  Icon1 ?: any;
  Icon2 ?: any;
  changeFunction ?: any;
  checkedCondition ?: boolean;
  disabled ?: boolean;
  className ?: string;
  defaultChecked ?: boolean;
  id?: string;
}) => {
  const {Icon1, Icon2, changeFunction, checkedCondition, disabled, className, defaultChecked, id} = props;
  return (
    <label className={`flex items-center cursor-pointer ${className}`}>
      <input
        id = {id}
        type="checkbox"
        className="sr-only"
        checked={checkedCondition}
        onChange={changeFunction}
        disabled = {disabled}
        defaultChecked = {defaultChecked}
      />
      {/* Switch */}
      <div className="relative">
        <div
          className={`block w-14 h-8 rounded-full shadow-inner ${
            checkedCondition ?(disabled ? 'bg-blue-300 cursor-not-allowed':'bg-blue-600' ): 'bg-gray-300'
          }`}
        />
        {/* Switch indicator */}
        <div
          className={`absolute left-1 top-1 w-6 h-6 rounded-full transition-transform ${
            checkedCondition ? 'transform translate-x-full bg-white' : 'bg-white'
          }`}
        >
          {
          (Icon1 && Icon2) && (checkedCondition ? (
            <Icon1 className="text-brand-500 h-full w-full p-1" />
          ) : (
            <Icon2 className="h-full w-full p-1" />
          ))}
        </div>
      </div>
    </label>
  );
};

export default Toggle;
