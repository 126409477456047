import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';
import header from './header';
import loader from './loading';
import auth from './authentication';
import custom from './custom';
import userSettings from './user-settings';
import campaign from './campaign';
import emailAccount from './email-accounts';
import domain from './domain'
import trueVerifier from './true-verifier';

const rootReducer = combineReducers({
  custom,
  auth,
  header,
  loader,
  userSettings,
  campaign,
  emailAccount,
  domain,
  trueVerifier
});

const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
