import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';

import SignIn from 'views/auth/sign-in';
import SignUp from 'views/auth/sign-up';
import HomeLayout from 'views/home-layout';
import SiteValidationList from 'views/seo/site-validation-list';
import UploadProspectsUrlList from 'views/seo/upload-site-validation-data';
import TrafficCountryList from 'views/seo/traffic-list-page';
import UploadTrafficValidationList from 'views/seo/upload-traffic-validation-data';
import TrafficValidationResult from 'views/seo/traffic-validation-result';
import TrafficTrendList from 'views/seo/traffic-trend-list-page';
import UploadTrafficTrendValidationList from 'views/seo/traffic-trend-upload-data';
import TrafficTrendValidationResult from 'views/seo/traffic-trend-result-page';
import SeoValidationResult from 'views/seo/site-validation-result';
import UploadOrganicKeywordValidationList from 'views/seo/upload-organic-keyword-data';
import OrganicKeywordList from 'views/seo/organic-keyword-list-page';
import OrganicKeywordResult from 'views/seo/organic-keyword-result-page';
import WebScraper from 'views/web-scraper';
import UploadKeywordPositionProspectsList from 'views/seo/seo-keyword-position-upload-page';
import SeoKeywordSearchListPage from 'views/seo/seo-keyword-position-list-page';
import SeoKeywordPositionSearchResultPage from 'views/seo/seo-keyword-position-result-page';

// import SiteValidationList from 'views/campaigns';
// import EmailVerificationResult from 'views/email-verify/features/EmailVerificationResult';
// import UploadProspectsUrlList from 'views/email-verify/features/UploadProspectsUrlList';

const appRouter = createBrowserRouter(
  [
    {
      path: '/',
      element: <HomeLayout />,
      children: [
        {
          path: '/seo',
          children: [
            {
              path: 'list',
              element: <SiteValidationList />,
            },
            {
              path: 'create',
              element: <UploadProspectsUrlList />,
            },
            {
              path: 'result',
              element: <SeoValidationResult />,
            },
            {
              path: 'traffic/list',
              element: <TrafficCountryList />,
            },
            {
              path: 'traffic/create',
              element: <UploadTrafficValidationList />,
            },
            {
              path: 'traffic/result',
              element: <TrafficValidationResult />,
            },
            {
              path: 'traffictrend/list',
              element: <TrafficTrendList />,
            },
            {
              path: 'traffictrend/create',
              element: <UploadTrafficTrendValidationList />,
            },
            {
              path: 'traffictrend/result',
              element: <TrafficTrendValidationResult />,
            },
            {
              path: 'organickeyword/list',
              element: <OrganicKeywordList />,
            },
            {
              path: 'organickeyword/create',
              element: <UploadOrganicKeywordValidationList />,
            },
            {
              path: 'organickeyword/result',
              element: <OrganicKeywordResult />,
            },
// ---------------------------------------
            {
              path: 'keywordposition/list',
              element: <SeoKeywordSearchListPage />,
            },

            {
              path: 'keywordposition/create',
              element: <UploadKeywordPositionProspectsList />,
            },

            {
              path: 'keywordposition/result',
              element: <SeoKeywordPositionSearchResultPage />,
            },
          ],
        },
        {
          path: '/web/scraper',
          children: [
            {
              path: '',
              element: <WebScraper />,
            },
          ]
        }
      ],
    },
    {
      path: '/sign-up',
      element: <SignUp />,
    },
    {
      path: '/sign-in',
      element: <SignIn />,
    },
    {
      path: '*',
      element: <Navigate replace to="/sign-in" />,
    },
  ]

);

const MainRoutes = (): JSX.Element => {
  return (
    <Provider store={store}>
      <RouterProvider router={appRouter} />
    </Provider>
  );
};
export default MainRoutes;
