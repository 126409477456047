import { Checkbox } from '@chakra-ui/react'
import CustomBadge from 'components/badges/CustomBadge'
import { MdModeEdit} from 'react-icons/md'
import PreviewEmail from './PreviewEmail'
import { useAppDispatch, useAppSelector } from 'store'
import { setCurrentHeaderSteps, updateCampaignName } from 'store/campaign'
import TestEmail from './TestEmail'
import { useRef } from 'react'

const AsideNav = ({senderEmailsList}:any) => {
    const dispatch = useAppDispatch()
    const campaign = useAppSelector((state) => state?.campaign);
    const inputRef = useRef(null)
    return (
        <div className='2xl:w-1/3 sm:w-1/2 bg-white 2xl:px-10 sm:px-4 py-8 rounded-xl drop-shadow-2xl shadow-gray-500/50 flex flex-col gap-2'>
            <div className='mb-5'>
                <p className="text-lg font-bold text-navy-700 dark:text-white">
                    Summary
                </p>
                <p className="text-sm text-gray-600">
                    Select Schedule</p>
            </div>
            <div className="group relative p-4 flex items-center justify-between rounded-2xl bg-white shadow-xl shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Campaign Name
                </h5>
                <h3 className="font-bold text-brand-500 hover:cursor-pointer dark:text-white">
                            <input ref={inputRef} id='campaign-name' value={campaign?.campaignName} onFocus={e => e.target.select()} type="text" placeholder='Campaign'
                                className='w-40 select-all px-1 outline-0 cursor-pointer' onChange={(e) => dispatch(updateCampaignName(e.target.value))} />
                        </h3>
                <MdModeEdit className='cursor-pointer group-hover:block hidden absolute right-4 top-2 text-gray-700'
                onClick={()=> inputRef.current.focus()}/>
            </div>
            <div className="group relative p-4 flex flex-col items-start justify-between rounded-2xl bg-white shadow-xl shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Sending to
                </h5>
                <div  className='flex w-full flex-wrap mt-8 gap-3 justify-between'>
                    <div id='prospect-mail-id' className='flex flex-col gap-3'>
                      {[...campaign?.campaignProspectsEmailListUploadCSVFinalData, ...campaign?.campaignProspectsAddManuallyEmailList]?.slice(0,2).map(
                        (item : any, index: number)=>{
                            return  <CustomBadge
                            key={index}
                            extra='text-xs'
                            id={`prospect-${index}`}
                            content={item?.email}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                        }
                      ) }

                    </div>
                    <div className='self-end mr-0'>
                        <CustomBadge
                            extra='text-xs underline text-nowrap'
                            content={`Review More`}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                    </div>
                </div>
                <MdModeEdit id='sending-mail-edit-btn' onClick={()=> dispatch(setCurrentHeaderSteps(1))}
                className='group-hover:block hidden absolute right-4 top-4 text-gray-700'/>
            </div>
            <div className="relative group p-4 flex flex-col items-start justify-between rounded-2xl bg-white shadow-lg shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Email Sending accounts
                </h5>
                <div className='flex w-full flex-wrap mt-8 gap-3 justify-between'>
                <div id='sender-email-id' className='flex flex-col gap-3'>
                      {
                  senderEmailsList
                  .filter((item: any)=> item !== undefined)
                  .slice(0,2).map(
                        (item : any, index : number)=>{
                           return item &&  <CustomBadge
                            key={index}
                            id={`sender-${index}`}
                            extra='text-xs'
                            content={item}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                        }
                      ) }

                    </div>
                    <div className='self-end ml-4'>
                        <CustomBadge
                            extra='text-xs underline text-nowrap'
                            content={`Review More`}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                    </div>
                </div>
                <MdModeEdit id='sender-mail-edit-btn' onClick={()=> dispatch(setCurrentHeaderSteps(3))}
                 className='group-hover:block hidden absolute right-4 top-4 text-gray-700'/>

            </div>
          
            {/* <div className="group relative p-4 flex flex-col items-start justify-between rounded-2xl bg-white shadow-xl shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Linkedin Sending accounts
                </h5>
                <div className='flex h-20 justify-between w-full'>
                    <div className='flex flex-col'>
                        <CustomBadge
                            extra='text-md'
                            content={`Dewey Lemke`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                        <CustomBadge
                            extra='text-md'
                            content={`Jonas Lewey`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                    </div>
                    <div className='self-end ml-4'>
                        <CustomBadge
                            extra='text-xs underline text-nowrap'
                            content={`Review More`}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                    </div>
                </div>
                <MdModeEdit className='group-hover:block hidden absolute right-4 top-4 text-gray-700'/>

            </div>
            <div className="group relative p-4 flex flex-col items-start justify-between rounded-2xl bg-white shadow-xl shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Phone No.
                </h5>
                <div className='flex self-center justify-between w-full'>
                    <div className='flex flex-col'>
                        <CustomBadge
                            extra='text-md'
                            content={`+44 894-094-870`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                        <CustomBadge
                            extra='text-md'
                            content={`+44 392-567-867`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                    </div>
                    <div className='self-end ml-4'>
                        <CustomBadge
                            extra='text-xs underline text-nowrap'
                            content={`Review More`}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                    </div>
                </div>
                <MdModeEdit className='group-hover:block hidden absolute right-4 top-4 text-gray-700'/>

            </div>
            <div className="group relative p-4 flex flex-col items-start justify-between rounded-2xl bg-white shadow-xl shadow-gray-500/50">
                <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                    Twitter sending accounts
                </h5>
                <div className='flex self-center justify-between w-full'>
                    <div className='flex flex-col'>
                        <CustomBadge
                            extra='text-md'
                            content={`Dewey Lemke`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                        <CustomBadge
                            extra='text-md'
                            content={`Jonas Lewey`}
                            color={'brand-500'}
                            bgColor={``}
                        />
                    </div>
                    <div className='self-end ml-4'>
                        <CustomBadge
                            extra='text-xs underline text-nowrap'
                            content={`Review More`}
                            color={'brand-500'}
                            bgColor={`indigo-50`}
                        />
                    </div>
                </div>
                <MdModeEdit className='group-hover:block hidden absolute right-4 top-4 text-gray-700'/>
            </div> */}
          
            <div className="p-3 pl-5 pb-5 font-medium flex flex-col rounded-2xl bg-white shadow-xl shadow-gray-500/50">
            <h5 className="text-sm mb-6 font-medium text-navy-700 dark:text-white">
                    Tracking Settings
                </h5>
                <div className='flex flex-col gap-2'>

                <Checkbox colorScheme='purple'>Track Opens</Checkbox>
                <Checkbox colorScheme='purple'>Track link clicks</Checkbox>
                <Checkbox colorScheme='purple'>Stop for People who reply</Checkbox>
                </div>
            </div>
            <div className="p-3 pl-5 flex flex-col rounded-2xl bg-white shadow-xl shadow-gray-500/50">
            <h5 className="text-sm font-medium text-navy-700 dark:text-white mt-2 mb-8">
                    Advanced Settings
                </h5>
                <div className='flex flex-col gap-2 mx-3'>
                  <PreviewEmail />
                  <TestEmail />
                </div>
            </div>
        </div>
    )
}

export default AsideNav