import React from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import { setLastSelectedItem } from 'store/campaign'

interface CheckBoxProps {
    info?: any,
    table?: any
}

const CheckBox = (props: CheckBoxProps) => {
    const { info, table } = props
    const dispatch = useAppDispatch()
    const campaign = useAppSelector(state => state?.campaign)

    const shiftSelect = (e: any) => {
        console.log(campaign?.lastSelectedItem)

        var _target;
        info?.row?.toggleSelected((_target = e.target) == null ? void 0 : _target.checked);

        if (e?.nativeEvent?.shiftKey) {
            const selection = (campaign?.lastSelectedItem  < info?.row?.id) ?
            table.getCoreRowModel().rows.slice(Number(campaign?.lastSelectedItem), Number(info?.row?.id)):
            table.getCoreRowModel().rows.slice(Number(info?.row?.id),Number(campaign?.lastSelectedItem))

           selection.map((row: any) => {
                if (!row.getIsSelected()) row.toggleSelected()
                return ""
            })
            dispatch(setLastSelectedItem(info?.row?.id))
        }
        else {
            dispatch(setLastSelectedItem(info?.row?.id))
            // console.log(table?.getCoreRowModel().rowsById[0]?.toggleSelected())
        }

    }

    return (
        <input type="checkbox"
            id={`row-${info?.row?.id + 1}`}
            checked={info?.row?.getIsSelected()}
            // disabled={!info?.row?.getCanSelect()}
            // onChange={info?.row?.getToggleSelectedHandler()}
            onChange={e => shiftSelect(e)}
            className='size-4 accent-brand-500 dark:accent-purple-600 cursor-pointer' />
    )
}

export default CheckBox