import TrackingComponent from './features/Tracking';
import ScheduleComponent from './features/Schedule';

const CampaignScheduleTracking = () => {
  return (  
      <div className='w-full mt-8 overflow-y-auto'>
          <div>
          <div className="w-full flex flex-col gap-5 px-10">
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
              Schedule & Tracking
            </h4>
            <p className="text-sm font-semibold text-gray-800">
              Set Campaign Start Time
            </p>
          </div>
          <ScheduleComponent />
          <TrackingComponent />
        </div>
      </div> 
  )
}

export default CampaignScheduleTracking




