// Custom components
import { useState } from 'react';
import { MdClose } from 'react-icons/md';

function TagsField(props: {
  id?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  placeholderTags?: { name: string; id: number }[];
  onChange?: any;
  pattern?: any;
  limit?: number;
  type?: string;

  [x: string]: any;
}) {
  const {
    label,
    id,
    className,
    onChange,
    placeholderTags,
    placeholder,
    pattern,
    limit,
    type,
    ...rest
  } = props;
  let initialTags: any[] | (() => any[]) = [];
  if (placeholderTags) initialTags = placeholderTags;
  const [tags, setTags] = useState(initialTags);
  const [inputText, setInputText] = useState('');

  const validateAndUpdate = (value: any) => {
    // const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
    const patternTest = pattern?.test(value);
    return patternTest;
  };
  // console.log('limit', limit, 'TagesLen', tags.length);
  const keyPress = async (e: any) => {
    if (e.keyCode === 13 && e.target.value) {
      const test = pattern ? validateAndUpdate(e.target.value) : true;
      if (test) {
        if (limit && tags.length < limit) {
          // const length = tags?.length;
          // console.log('first', length);
          if (tags.length < limit) {
            // console.log('first2', length);
            setTags([
              ...tags,
              {
                name: e.target.value,
                id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
              },
            ]);
          }
        } else {
          // console.log('first3');
          setTags([
            ...tags,
            {
              name: e.target.value,
              id: tags.length === 0 ? 1 : tags[tags.length - 1].id + 1,
            },
          ]);
        }

        setInputText('');
      }
    }

    // Backspace deleting code activates only when current input is empty
    if (!inputText && e.keyCode === 8) {
      const tempTag = tags.slice();
      tempTag.pop();
      setTags(tempTag);
      setInputText('');
      onChange(tempTag);
    }
  };

  // .split(/[\s,]+/)
  // console.log('length', tags?.length)
  const OnPasteTrigger = (e: any) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData('text');
    let pasteList = type === 'keyword' ? pasteText
      .split(/[,]+/)
      .map((item: string, index: number) => {
        return { name: item, id: tags.length + (index + 1) };
      }): pasteText
      .split(/[\s,]+/)
      .map((item: string, index: number) => {
        return { name: item, id: tags.length + (index + 1) };
      })
    pasteList = pattern
      ? pasteList.filter((item: any) => validateAndUpdate(item.name))
      : pasteList;
    // console.log('pasteList.length', pasteList.length);
    if (pasteList.length) {
      let newTag = [];
      if (tags.length > 0 && pasteList.length > 0) {
        newTag = [...tags, ...pasteList].slice(0, limit);
        // setTags([...tags, ...pasteList].slice(0, limit));
        // setInputText('');
        // onChange([...tags, ...pasteList].slice(0, limit));
      } else if (tags.length < pasteList.length) {
        newTag = [...tags, ...pasteList];

        // setTags([...tags, ...pasteList]);
        // setInputText('');
        // onChange([...tags, ...pasteList]);
      } else {
        const pasteValue = pasteList;
        newTag = [...tags, ...pasteValue].slice(0, limit);

        // setTags([...tags, ...pasteValue]);
        // setInputText('');
        // onChange([...tags, ...pasteValue]);
      }

      setTags(newTag);
      setInputText('');
      onChange(newTag);
    }
  };
  return (
    <div>
      <label htmlFor={id} className={'mb-2 text-sm font-bold'}>
        {label}
      </label>
      <div
        className={`h-stretch relative flex min-h-10 flex-row flex-wrap rounded-xl border border-gray-200 bg-none p-3 focus:border-brand-200 dark:!border-white/10`}
        {...rest}
        onBlur={validateAndUpdate}
      >
        <div
          id="email-lists"
          className="flex max-h-32 w-full  flex-wrap overflow-y-auto"
        >
          {tags.map((tag, index) => {
            return (
              <div
                className="align-center mb-1.5 mr-1.5 flex h-7 items-center rounded-xl bg-brand-500 px-3 py-0.5 text-sm font-medium hover:bg-brand-600"
                key={index}
              >
                <p className="mr-1 w-full text-white">{tag.name}</p>
                <MdClose
                  className="justify-end text-white hover:scale-150"
                  onClick={() => {
                    setTags([
                      ...tags.filter((element) => element.id !== tag.id),
                    ]);
                    onChange([
                      ...tags.filter((element) => element.id !== tag.id),
                    ]);
                  }}
                />
              </div>
            );
          })}
        </div>
        <input
          autoComplete="off"
          onPaste={(e: any) => OnPasteTrigger(e)}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13) {
              return onChange(tags);
            }
          }}
          type="text"
          onKeyDown={(e) => keyPress(e)}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          id="username"
          placeholder={placeholder}
          className={`username flex w-full border-none bg-none p-0 text-sm outline-none dark:!bg-navy-800 ${className}`}
        />
        {/* <button onClick={() => { setTags(initialTags); onChange([]) }}
          className="absolute right-0 bottom-0 mx-3 my-3 text-2xl duration-300 hover:scale-105 active:text-red-700 rounded-xl hover:text-red-500 text-red-400">
            <BiReset />
          </button> */}
      </div>
    </div>
  );
}

export default TagsField;
