import InputField from "components/fields/InputField";
import LogoCard from "../components/LogoCard";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { FormEvent, useState } from "react";
import { BaseApi } from "common/api/common/base-api";
import { getErrorMessage } from "store/custom";
import { SignupFormDTO } from "common/dto/types/views/authentication";
import { SignupFormData } from "common/dto/initial-value/views/authentication";
import SignupWithGoogle from "../components/SignupWithGoogle";
import GoogleOAuthContainer from "components/google-oauth";

function SignUpDefault() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { errorMessage }: any = useAppSelector((state) => state.custom);
    const [formData, setFormData] = useState<SignupFormDTO>(SignupFormData);
    const handleFormChange = (e: { target: { id: any; value: any; }; }): void => setFormData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const data = await BaseApi.post('/auth/signup', formData);
            if (data?.error) {
                dispatch(getErrorMessage(data?.message))
            } else {
                navigate('/sign-in')
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (

        <div>
            <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
                <main className={`mx-auto min-h-screen`}>
                    <FixedPlugin />
                    <LogoCard
                        maincard={
                            <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                                {/* Sign up section */}
                                <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                                    <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                                        Sign Up
                                    </h3>
                                    <p className="mt-[10px] ml-1 text-base text-gray-600">
                                        Enter your email and password to sign up!
                                    </p>

                                    <GoogleOAuthContainer source={<SignupWithGoogle text="Sign Up with Google" />} />

                                    <div className="mt-6 mb-4 flex items-center gap-3">
                                        <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                                        <p className="text-base font-medium text-gray-600"> or </p>
                                        <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                                    </div>
                                    {/* user info */}
                                    <form onSubmit={(e) => handleFormSubmit(e)}>
                                        <div className="mb-3 flex w-full items-center justify-center gap-4">
                                            <div className="w-1/2">
                                                <InputField
                                                    variant="auth"
                                                    extra="mb-3"
                                                    label="First Name*"
                                                    placeholder="John"
                                                    id="first_name"
                                                    type="text"
                                                    onChange={handleFormChange}
                                                    value={formData?.first_name}
                                                    errorMessage={errorMessage?.first_name}
                                                    state={errorMessage?.first_name ? 'error' : ''}
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <InputField
                                                    variant="auth"
                                                    extra="mb-3"
                                                    label="Last Name*"
                                                    placeholder="Doe"
                                                    id="last_name"
                                                    type="text"
                                                    onChange={handleFormChange}
                                                    value={formData?.last_name}
                                                    errorMessage={errorMessage?.last_name}
                                                    state={errorMessage?.last_name ? 'error' : ''}
                                                />
                                            </div>
                                        </div>
                                        {/* Email */}
                                        <InputField
                                            variant="auth"
                                            extra="mb-3"
                                            label="Email *"
                                            placeholder="mail@simmmple.com"
                                            id="email"
                                            type="email"
                                            onChange={handleFormChange}
                                            value={formData?.email}
                                            errorMessage={errorMessage?.email}
                                            state={errorMessage?.email ? 'error' : ''}
                                        />
                                        {/* Mobile */}
                                        <InputField
                                            variant="auth"
                                            extra="mb-3"
                                            label="Mobile *"
                                            placeholder="+91 98xxxxxxxxx"
                                            id="mobile"
                                            type="tel"
                                            onChange={handleFormChange}
                                            value={formData?.mobile}
                                            errorMessage={errorMessage?.mobile}
                                            state={errorMessage?.mobile ? 'error' : ''}
                                        />
                                        {/* Password */}
                                        <InputField
                                            variant="auth"
                                            extra="mb-3"
                                            label="Password *"
                                            placeholder="Min 6 characters"
                                            id="password"
                                            type="password"
                                            onChange={handleFormChange}
                                            value={formData?.password}
                                            errorMessage={errorMessage?.password}
                                            state={errorMessage?.password ? 'error' : ''}
                                        />
                                        {/* Confirm Password */}
                                        <InputField
                                            variant="auth"
                                            extra="mb-3"
                                            label="Confirm Password *"
                                            placeholder="Min 6 characters"
                                            id="confirm_password"
                                            type="password"
                                            onChange={handleFormChange}
                                            value={formData?.confirm_password}
                                            errorMessage={errorMessage?.confirm_password}
                                            state={errorMessage?.confirm_password ? 'error' : ''}
                                        />

                                        {/* button */}

                                        <button className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                            Create my account
                                        </button>
                                    </form>

                                    <div className="mt-3">
                                        <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                                            Already a member?
                                        </span>
                                        <Link to={'/sign-in'} className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white" >Sign In</Link>
                                    </div>

                                </div>
                            </div>
                        }
                    />
                </main>
            </div>
        </div>
    );
}

export default SignUpDefault;
