/* eslint-disable-next-line react-hooks/exhaustive-deps */

import Card from 'components/card';
import TagsField from 'components/fields/TagsField';
import { useNavigate } from 'react-router-dom';
import BackArrow from 'assets/svg/backArrow.svg';

import { ReactSVG } from 'react-svg';
import { useState } from 'react';
import { BaseApi } from 'common/api/common/base-api';
import { Spinner } from '@chakra-ui/react';
import UploadCSV from '../feature/UploadCSV';
import { EDIT_ID } from 'common/constants/constants';
import { SessionStorage } from 'common/api/common/storage';

const UploadOrganicKeywordValidationList = () => {
  const navigate = useNavigate();

  const [seotrafficform, setSeoTrafficForm] = useState({
    client_url_name: '',
    url_list: [],
    upload_list: [],
    limit: 25,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    // console.log(seotrafficform?.upload_list?.length)

    let result: any = {};
    result.client_url_name = seotrafficform.client_url_name;
    const list = [...seotrafficform?.url_list, ...seotrafficform?.upload_list];
    result.url_list = list?.map((item: any, index: number) => ({
      id: index + 1,
      url: item.name,
    }));
    result.limit = seotrafficform.limit;

    // }
    try {
      // console.log(result, "lll")
      let dbdata = await BaseApi.post(
        `/seoorganickeyword/organickeyword`,
        result
      );
      let dbDataUuid = dbdata?.data?.uuid;
      // console.log("UUSI", dbDataUuid);
      SessionStorage.setString(EDIT_ID, dbDataUuid);

      setLoading(false);

      // console.log("API DATA FROM DB", dbdata);
      // if (dbDataUuid !== null || undefined) {
      navigate('/seo/organickeyword/result');
      // }
    } catch (error) {
      console.error('Error form Organic KeywordPage: ', error);
    }
  };

  const validateResult = (result: any) => {
    return (
      result.client_url_name !== null &&
      (result.url_list.length || result.upload_list.length)
    );
  };

  const handleChange = (name: any, value: any) => {
    setSeoTrafficForm((prevState: any) => {
      const result = { ...prevState };

      if (Array.isArray(prevState[name])) {
        const uniqueTracker: any = {};
        const testfield = value.filter(
          (item: { [key: string]: string | number }) => {
            const itemStr = item.name;
            if (!uniqueTracker[itemStr]) {
              uniqueTracker[itemStr] = true;
              return true;
            }
            return false;
          }
        );
        result[name] = testfield;
      } else {
        result[name] = value;
      }

      const condition = validateResult(result);
      setIsButtonDisabled(!condition);
      return result;
    });
  };

  return (
    <div
      className={`mt-2 h-screen w-full overflow-auto rounded-lg bg-white p-2 shadow-sm dark:bg-navy-700 dark:text-white`}
    >
      <button
        id="campaign-close-btn"
        onClick={() => {
          navigate('/seo/traffic/list');
        }}
      >
        <ReactSVG src={BackArrow} className="scale-[0.8]" />
      </button>
      <div className="mb-24 flex items-center justify-center">
        <div className="sm:w-[80%] 2xl:w-[55%]">
          <Card extra={'w-full mt-3 px-6 pt-5'}>
            {/* Header */}
            <div className="w-full px-2">
              <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                Organic Keyword Validation Input
              </h4>
            </div>

            <div className="mt-3 px-2 py-3 pb-6 outline-none dark:!border-white/10 sm:w-full md:w-full">
              <h4 className="mb-1 flex items-center text-xs font-bold text-navy-700 dark:text-white">
                Client Url
              </h4>
              <input
                id="organic-keyword-client-url-name"
                className="w-full  rounded-md border p-2 text-sm outline-none"
                value={seotrafficform.client_url_name}
                onChange={(event) =>
                  handleChange('client_url_name', event?.target?.value)
                }
                placeholder="Client Url Name"
              />
            </div>

            <div className=" item-center  flex flex-col justify-between gap-4 pl-2 outline-none dark:!border-white/10 sm:w-[94%] md:w-[94%]">
              <h4 className="flex items-center text-[11px] font-bold text-navy-700 dark:text-white">
                Add Prospects Url
              </h4>
            </div>

            <UploadCSV
              onChange={(value) => handleChange('upload_list', value)}
            />

            <div className="mt-5 px-2 outline-none dark:!border-white/10 sm:w-full md:w-full">
              <h4 className="text-[11px]  font-bold text-navy-700 dark:text-white">
                Add Prospects Url Manually
              </h4>
              <div className="col-span-1 row-span-2  mt-1  h-full">
                <TagsField
                  id="description"
                  className="prospects-input pb-24 pt-4"
                  onChange={(value: any) => handleChange('url_list', value)}
                  limit={500}
                  // placeholderTags={campaign?.campaignProspectsAddManuallyEmailList?.map(({ email: name, ...rest }: any) => ({ ...rest, name }))}
                  pattern={
                    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/
                  }
                />
              </div>
            </div>

            <div className="mt-3 px-2 py-3 pb-6 outline-none dark:!border-white/10 sm:w-full md:w-full">
              <button
                onClick={() => {
                  handleSubmit();
                }}
                id="organic-keyword-start-btn"
                disabled={isButtonDisabled}
                className={`mx-1 flex h-8 w-full items-center justify-center gap-0.5 rounded-md ${
                  isButtonDisabled
                    ? 'bg-gray-400'
                    : 'bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                } 
                            px-2 py-1.5 text-sm font-medium text-white transition duration-200`}
              >
                {loading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.55s"
                    emptyColor="blue"
                    color="White"
                    size="md"
                  />
                ) : (
                  <span className="text-nowrap text-[15px]">
                    Start Validation
                  </span>
                )}
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UploadOrganicKeywordValidationList;
