const CustomBadge = (props:{
    content : string | JSX.Element;
    color: string;
    bgColor: string;
    extra ?: string;
    id ?: string
}) => {
    const {color, bgColor, extra, content, id} = props
  return (
    <div id={id} className={`inline-block p-2 bg-${bgColor} text-${color} font-bold rounded-full ${extra}`} >
        {content}
    </div>
  )
}

export default CustomBadge