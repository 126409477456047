import { Button, Checkbox, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuList, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tooltip } from '@chakra-ui/react'
import { BaseApi } from 'common/api/common/base-api'
import { jwtDecrypt, setAndGetQueryParams } from 'common/utils/utility'
import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowUp, IoMdAdd } from 'react-icons/io'
import { useAppDispatch, useAppSelector } from 'store'
import { setCampaignSettingSenderEmailGroupsList, setCampaignSettingSenderEmailList } from 'store/campaign'

const CampaignSettings = () => {
    const dispatch = useAppDispatch();
    const { campaignSettingSenderEmailList, campaignSettingSenderEmailGroupsList } = useAppSelector((state) => state?.campaign);
    const [senderEmailsList, setSenderEmailsList] = React.useState([]);
    const [senderEmailGroupsList, setSenderEmailGroupsList] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [arrowMark, setArrowMark] = useState(false)
    React.useEffect(() => {
        const fetchList = async () => {
            const query = setAndGetQueryParams([{ key: 'search', value: search },{ key: 'limit', value: 100 }])
            try {
                // email accounts list api
                const emailAccountsResponse = await BaseApi.get(`/email/settings/connected/list?${query}`);
                const { data }: any = jwtDecrypt(emailAccountsResponse?.data)
                if (data && data?.length) {
                    setSenderEmailsList(data)
                }
                // email accounts list api
                const groupsResponse = await BaseApi.get(`/email/groups?${query}`);
                if (!groupsResponse?.error && groupsResponse?.data?.data?.length) {
                    setSenderEmailGroupsList(groupsResponse?.data?.data)
                }

            } catch (error) {
                console.log(error)
            }
        }
        fetchList();
    }, [search])

    const handleSelectEmailsAndGroupsCheckbox = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        if (e.target.checked && e.target.value === 'all') {
            if (type === 'emails') {
                dispatch(setCampaignSettingSenderEmailList(senderEmailsList.map((item) => item.uuid)))
            }
            if (type === 'groups') {
                dispatch(setCampaignSettingSenderEmailGroupsList(senderEmailGroupsList.map((item) => item.uuid)))
            }
        }
        if (!e.target.checked && e.target.value === 'all') {
            if (type === 'emails') {
                dispatch(setCampaignSettingSenderEmailList([]))
            }
            if (type === 'groups') {
                dispatch(setCampaignSettingSenderEmailGroupsList([]))
            }
        }
        if (e.target.value !== 'all') {
            if (type === 'emails') {
                const senderEmailList = (e.target.checked) ? [...campaignSettingSenderEmailList, e.target.value] : campaignSettingSenderEmailList.filter((item: any) => item !== e.target.value)
                dispatch(setCampaignSettingSenderEmailList(senderEmailList))
            }
            if (type === 'groups') {
                const senderEmailGroupsList = (e.target.checked) ? [...campaignSettingSenderEmailGroupsList, e.target.value] : campaignSettingSenderEmailGroupsList.filter((item: any) => item !== e.target.value)
                dispatch(setCampaignSettingSenderEmailGroupsList(senderEmailGroupsList))
            }
        }
    }
    return (
        <div className='flex justify-center items-center mt-6 '>
            <div className='w-full px-[45px]'>
                <div className="my-3 pt-1">
                    {/* Header */}
                    <div className="w-full mt-3">
                        <h4 className="text-[19px] font-bold text-navy-700 dark:text-white">
                            Campaign Setting
                        </h4>
                    </div>
                    <div>
                        <div className="flex items-center gap-6 xl:gap-6 lg:gap-2 sm:gap-6 mt-6">
                            <div className='flex flex-col justify-center'>
                                <label className="text-[11px] flex items-center font-bold text-navy-700 dark:text-white">
                                    Select Sender Email
                                </label>
                                <Menu>
                                    <MenuButton as={Button}
                                    id='menu-btn'
                                    onClick={()=> setArrowMark(!arrowMark)}
                                    onBlur={()=> setArrowMark(false)}
                                    rightIcon={arrowMark ? 
                                    <IoIosArrowDown size={14} className='text-blue-600' /> :
                                    <IoIosArrowUp size={14} className='text-blue-600' />
                                    } className='w-52 mt-1 -ml-1 !text-start !bg-indigo-50 !text-xs !text-gray-600 !border !rounded-md hover:!bg-blue-50' fontWeight={500}>
                                        <span className='text-[11px]'>{(campaignSettingSenderEmailGroupsList?.length || campaignSettingSenderEmailList.length) ? `Selected - ${campaignSettingSenderEmailList.length} Emails, ${campaignSettingSenderEmailGroupsList?.length} Groups` : 'Select email groups'}</span>
                                    </MenuButton>
                                    <MenuList className=' !rounded-md !shadow-md !px-5'>
                                        <Tabs variant="unstyled">
                                            <TabList className='flex '>
                                                <Tab className='!text-xs'>Emails</Tab>
                                                <Tab className='!text-xs'> Groups</Tab>
                                            </TabList>
                                            <TabIndicator className='bg-brand-500 h-1 !w-30 rounded-xl mt-[-1.5px]' />

                                            <InputGroup className='mt-3 bg-blue-50 rounded-2xl'>
                                                <InputLeftElement pointerEvents='none' >
                                                    <FaSearch className='text-gray-700 text-xs' />
                                                </InputLeftElement >
                                                <Input type='email' placeholder='Search' className='!rounded-full !py-0 !text-xs'
                                                 value={search}onChange={(e) => setSearch(e.target?.value)} />
                                            </InputGroup>
                                            <TabPanels className='max-h-44 mt-2 overflow-y-auto'>
                                                <TabPanel className='flex flex-col gap-1 aaaaaaaaaaaaaa p-[0]'>
                                                    <Checkbox value={'all'} onChange={(e) => handleSelectEmailsAndGroupsCheckbox(e, 'emails')}>
                                                        <span className='!text-xs'>Select All</span>
                                                    </Checkbox>
                                                    {senderEmailsList?.length && senderEmailsList.map((item, index) => (
                                                        <Checkbox key={index} value={item?.uuid}
                                                            isChecked={campaignSettingSenderEmailList.includes(item?.uuid)}
                                                            onChange={(e) => handleSelectEmailsAndGroupsCheckbox(e, 'emails')}
                                                            className='rounded-full.
 '
                                                        >
                                                             <span className='!text-xs'>{item?.email}</span>
                                                        </Checkbox>
                                                    ))}
                                                </TabPanel>
                                                <TabPanel className='flex flex-col gap-1'>
                                                    <Checkbox value={'all'} onChange={(e) => handleSelectEmailsAndGroupsCheckbox(e, 'groups')}>
                                                        Select All
                                                    </Checkbox>
                                                    {senderEmailGroupsList?.length && senderEmailGroupsList.map((item, index) => (
                                                        <Checkbox key={index} value={item?.uuid}
                                                            isChecked={campaignSettingSenderEmailGroupsList.includes(item?.uuid)}
                                                            onChange={(e) => handleSelectEmailsAndGroupsCheckbox(e, 'groups')} >
                                                            <Tooltip hasArrow label={<>{item?.email_accounts_list?.map((row: any, index: number) => (<p>{index + 1}. {row?.email}</p>))}</>} aria-label='A tooltip'>
                                                                {item?.name}
                                                            </Tooltip>
                                                        </Checkbox>
                                                    ))}
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                    </MenuList>
                                </Menu>
                            </div>
                            <div className='flex flex-col'>
                                <label className="text-[11px] ml-2 -mt-1 flex items-center font-bold text-navy-700 dark:text-white">
                                    Add Manually
                                </label>
                                <button className="mt-1 px-20 flex gap-1 py-2.5 justify-center items-center linear rounded-md text-brand-500 shadow-lg border border-gray-100 font-medium duration-300 hover:cursor-pointer hover:text-white hover:bg-brand-500 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                                    <span><IoMdAdd size={15} /></span>
                                    <span className='text-xs font-medium'>Add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignSettings