import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setCurrentHeaderSteps } from 'store/campaign';

const Stepper = (props: {
    steps: {
        stepNo: number;
        name: string;
        highlighted?: boolean;
        selected?: boolean;
        completed?: boolean;
    }[];
    currentStep?: number;
    action?: any;
}) => {
    const currentHeaderStep = useAppSelector((state) => state?.campaign?.currentHeaderStep)
    const dispatch = useAppDispatch()
    const { steps } = props;
    const [newStep, setNewStep] = useState([]);

    const updateStep = (
        stepNumber: number,
        steps: {
            stepNo: number;
            name?: string;
            highlighted?: boolean;
            selected?: boolean;
            completed?: boolean;
        }[]
    ) => {
        const newSteps = [...steps];
        let count = 0;
        while (count < newSteps.length) {
            //current step
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true,
                };
                count++;
            } else if (count < stepNumber) {
                //step completed
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true,
                };
                count++;
            } else {
                //step pending
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false,
                };
                count++;
            }
        }

        return newSteps;
    };

    useEffect(() => {
        const stepsState = steps.map((step, index) =>
            Object.assign(
                {},
                {
                    stepNo: step.stepNo,
                    description: step.name,
                    completed: false,
                    highlighted: index === 0 ? true : false,
                    selected: index === 0 ? true : false,
                }
            )
        );

        const current = updateStep(currentHeaderStep - 1, stepsState);
        setNewStep(current);
    }, [steps, currentHeaderStep]);

    const stepsDisplay = newStep.map((step, index) => {
        return (
            <div
                key={index}
                className={
                    index !== 0 ? 'flex w-full items-center' : 'flex items-center'
                }
            >
                <div
                    className={`flex-auto border-t-[3px] transition duration-500 ease-in-out ${step.completed ? 'opacity-100 border-brandLinear ' : 'opacity-40 border-gray-400'
                        } `}
                />
                <div
                    onClick={() => dispatch(setCurrentHeaderSteps(step.stepNo))}
                    className="relative flex flex-col items-center hover:cursor-pointer"
                >
                    <div
                        className={`flex h-4 w-4 items-center justify-center rounded-full border-[3px] border-[var(--color-300)] from-brand-400 to-brand-600 transition duration-500 ease-in-out 
                        ${step.selected
                                ? 'border-[2px] !border-blueSecondary bg-indigo-300 font-bold text-gray-400'
                                : 'border-[2px] border-[var(--color-300)] border-gray-500'
                            }`}
                    />
                    <div
                        className={`absolute text-[11px] font-semibold top-0 pb-5 mt-[25px] hidden w-40 text-center md:block ${step.completed ?
                             'opacity-100 text-blueSecondary' : 'opacity-40'}`}
                    >
                        {step.description}
                        {currentHeaderStep === step.stepNo && <hr className='w-[90px] ml-9 mt-6 border-b-2 border-b-brand-500 border-t-brand-500' />}
                    </div>
                </div>
            </div>
        );
    });

    return ( 
        <div className="mx-4 mb-1 px-16 flex items-center justify-between">
            {stepsDisplay}
        </div>
    );
};
export default Stepper;
