import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DomainStoreState } from 'common/dto/types/views/domain';

const initialState :DomainStoreState = {
    dnsModal : false,
    addDomain : []
}

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    updateDNSModal : (state, action : PayloadAction<boolean>)=>{
        state.dnsModal = action.payload
    }, // -- DNS, DMARC, SPF, DKIM Stepper + Questionnaire Modal Status
    setAddDomain : (state, action)=>{
      state.addDomain = action.payload
    }
  }
});

export const {updateDNSModal,setAddDomain} = domainSlice.actions;
export default domainSlice.reducer;
